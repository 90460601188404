import styled from 'styled-components';
import { Form } from '@unform/web';

import ImageBg from '../../assets/bg.jpg';

import logo from '../../assets/logo.png';

export const Container = styled.div`
	width: calc(100% - 70px);
	height: calc(100% - 40px);
	display: flex;
	padding: 40px 0px 0px 70px;


	background-color: rgba(0, 0, 0, 0.2);
  background-image: url(${ImageBg}),linear-gradient(to top, #0ba360 0%, #3cba92 100%);
	background-size: cover;
	background-position: right;
	background-repeat: no-repeat;
	background-blend-mode: overlay;

	@media (max-width: 759px) {
		width: calc(100% - 40px);
		height: calc(100% - 40px);

		padding: 20px 20px 20px 20px;		
	}
`;

export const Content = styled.div`
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
`

export const Unform = styled(Form)`
	background: #fafafa;
	display:flex;
	flex-direction: column;
	border-radius: 6px;
	margin-top: auto;
	margin-bottom: auto;
	max-width: 500px;
	padding: 20px 20px 10px 20px;
	

	@media (max-width: 400px) {
		overflow: scroll;
	}


`

export const Divider = styled.div`
	background: #FF3CAC;
	width: 50px;
	height: 5px;
	margin-top: 20px;
	display:body;
`;

export const InputContainer = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
`;

export const SpanInput = styled.span`
	font-family: 'Bree Serif', serif;
	font-weight: 300;
	opacity: 0.7;
	color: #000;
`;

export const Input = styled.input`
	font-family: 'Bree Serif', serif;
	font-size: 18px;
	padding: 10px 0 10px 0;
	color: #008040;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	background: none;
	display: flex;
	flex-grow: 1;
`;

export const Title = styled.div`
	font-family: 'Bree Serif', serif;
	color: rgba(0,0,0,0.8);
	font-size: 25px;
	font-weight: 300;
`;

export const Logo = styled.img`
	width: 210px;
	height: 84px;
	display: flex;


	@media (max-width: 325px) {
		width: 170px;
		height: 64px;

		
	}
`;

export const Button = styled.button`
	display: flex;
	height: 52px;
	background: none;
	color: #3cba92;
	border-radius: 6px;
	margin-top: 10px;
	font-family: 'Bree Serif', serif;
	font-size: 18px;

	:hover {
		opacity: 0.7;
	}

	cursor: pointer;
`

export const Error = styled.span`
	font-family: 'Bree Serif', serif;
	font-weight: 300;
	opacity: 0.7;
	color: #f00;
	margin-top: 10px;
`



