import styled from 'styled-components';

export const Container = styled.section`
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    background-color: #00a76f;
    height: 70px;
    padding: 30px;

    h3 {
      color: #fafafa;
      font-size: 18px;
      opacity: 0.9;
      font-style: italic;
      margin: 0;
    }

    a{
      color: #fafafa;
      font-size: 18px;
      font-weight: bold;
      opacity: 0.9;
      font-style: italic;
      margin: 0;
      text-decoration: none;
    }

    h1 {
      color: #fafafa;
      margin: 0 0 10px 0;
      margin-bottom: 5px;
    }

    div {
      display: flex;
      flex-direction: column;

      span {
        color: var(--highlight-color);
        font-size: 20px;
        opacity: 0.9;
      }
    } 
  }

  form {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;

    h3 {
      color: #000;
      font-size: 18px;
      opacity: 0.4;
      width: calc(100% - 30px);
      margin: 10px 0 5px 30px;
    }

    
  }
`;
