import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100% !important;
  background-color: var(--background-color);
  padding: 40px;
	

  h2 {
    color: var(--main-color);
    font-size: 25px;
    font-family: 'Archivo', sans-serif;
    /* text-transform: capitalize !important; */
  }

  .accordion {
    color: var(--main-color);
    font-weight: bold !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 20px;
    
    box-shadow: 0 2px 4px 0 var(--main-color), 0 3px 10px 0 var(--transparency-main-color);
  }
`;

export const Content = styled.div`
	width: 100%;
	display: flex;

`
export const SectionTitle = styled.span`
	color: var(--main-color);
	font-size: 25px;
	margin-bottom: 15px;
`

export const InfoTitle = styled.span`
	margin-top: 15px;
	color: var(--main-color);
	font-size: 22px;
	opacity: 0.9;
`


export const Info = styled.span`
	color: var(--main-color);
	font-size: 18px;
	opacity: 0.9;
	font-family: 'Roboto', sans-serif;
`

export const Side = styled.div`
	padding: 50px;
	display: flex;
	flex-direction: column;

	@media (max-width: 450px) {
		padding: 30px 30px 0 30px;
	}
`;

export const Dependent = styled.div`
	max-width: 350px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid rgba(255, 255, 255, 0.6);
`;


export const MessageConta = styled.div`
	width: calc(100% - 80px);
	height: 60%;
	background: #F9F8FD;
	border-top: 1px solid rgba(0,0,0,0.1);
	display:flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 20px 40px 20px 40px;

	@media (max-width: 700px) {

		height: 60%;
		flex-direction: column-reverse;
	}
`;

export const MessageCover = styled.img`
	width: 400px;
	height: 400px;

`;

export const MessageDivider = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MessageTitle = styled.span`
	font-family: 'Patua One', cursive;
	color: #000;
	font-size: 45px;
	max-width: 550px;
	display: flex;

	@media (max-width: 900px) {
		font-size: 35px;
	}

	@media (max-width: 700px) {
		text-align: center;
	}

	@media (max-width: 550px) {
		font-size: 30px;
	}

	@media (max-width: 330px) {
		font-size: 23px;
		margin-top: 10px;
	}
`;

export const Message = styled.span`
	font-family: 'Open Sans', sans-serif;
	color: #323232;
	font-size: 16px;
	margin-top: 20px;

	max-width: 500px;

	@media (max-width: 900px) {
		font-size: 14px;
		max-width: 400px;
	}

	@media (max-width: 700px) {
		text-align: center;
		max-width: 550px;
		
	}
`;

export const NavBar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
`;

export const Link = styled.a`
	font-family: 'Open Sans', sans-serif;
	color: #0ba360;
	font-size: 15px;
	background: #3cba92;
	padding: 10px;
	border-radius: 6px;
	text-align: center;
	cursor: pointer;
`;

export const LinkCommon = styled.a`
	font-family: 'Open Sans', sans-serif;
	color: #0ba360;
	font-size: 15px;
	padding: 10px;
	border-radius: 6px;
	text-align: center;
	cursor: pointer;
`;



