import styled from 'styled-components';
import { Form as Unform  } from '@unform/web';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	background-color: var(--background-color);
`;

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin-left: 325px;
`

export const Content = styled.div`
	display: flex;
	width: 100%;
	height: calc(100% - 82px);
	justify-content: space-evenly;
	align-items: center;
`

export const Card = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: #fafafa;
	padding: 0px 20px 0px 20px;
	height: 200px;
	font-family: 'Bree Serif', serif;
	font-size: 30px;
	border-radius: 10px;
	cursor: pointer;

	:hover{
		opacity: 0.9;
	}
`

export const InputContainer = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
`;

export const SpanInput = styled.span`
	font-family: 'Bree Serif', serif;
	font-weight: 300;
	opacity: 0.7;
	color: var(--main-color);
`;

export const Button = styled.button`
	font-family: 'Bree Serif', serif;
	font-size: 18px;
	color:  #3cba92;
	background: none;	
`;

export const Form = styled(Unform)`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 90%;
`
