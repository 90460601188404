import React from 'react'

import { Container, Top, UserImage, UserName, PageGroupTitle, Page } from './styles'


import PageGroup from './components/PageGroup'
import ExitButton from './components/ExitButton'

import { FaUser, FaReceipt, FaCalculator, FaChartPie, FaBell, FaHandshake, FaHome } from 'react-icons/fa'


function Menu() {
	return (
		<Container>
			
		<PageGroup
			Icon={FaHome}
			title='VISÃO GERAL'
			color='#da0811'
			to="/painel"
		/>

		<PageGroup
			Icon={FaUser}
			title='TITULARES'
			color='5CD17B'
			to="/users"
		/>

		<PageGroup
			Icon={FaReceipt}
			title='PAGAMENTOS'
			color='E8B65A'
			to="/payments"
		/>

		<PageGroup
			Icon={FaChartPie}
			title='RELATÓRIOS'
			color='E06319'
			to="/report"
		/>

		<PageGroup
			Icon={FaHandshake}
			title='CONVÊNIOS'
			color='333'
			to="/benefits"
		/> 

		<PageGroup
			Icon={FaCalculator}
			title='SIMULADORA'
			color='C85EE0'
			to="/simulator"
		/>

		

		<PageGroup
			Icon={FaBell}
			title='NOTIFICAÇÕES'
			color='A48CEB'
			to="/notification"
		/> 

		<ExitButton>Sair da conta</ExitButton>
			
			
		</Container>
	)
}

export default Menu