import React, { useRef, useState } from 'react';

import { Container, Toolbar, SectionTitle, Unform, InputContainer, SpanInput, ButtonOutlined, Wrapper } from './styles';

import Input from '../../Components/Input';

import SideMenu from '../../Components/SideMenu';
import api from '../../Services/api';


export default function CreateUser({history}) {
	const [ loading, setLoading ] = useState(false)
	const [ error, setError ] = useState(false)
	const formRef = useRef()

	const submitForm = async (info) => {
		setLoading(true)

		const { data } = await api.post('/admin/user', info)

		if(!data.error) return history.replace(`/admin/user/${data.user}`)

		console.log(data.error)
		setLoading(false)
	}

	return (
		<Container>

			<Wrapper>
				<Toolbar>
					<SectionTitle>Novo Titular</SectionTitle>
					
				</Toolbar>

				<Unform onSubmit={submitForm} ref={formRef}>

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Nome</SpanInput>
						<Input 
							className="input"
							name="name"
							required={true}
							placeholder="Digite o nome completo"/>
					</InputContainer>
					

					<InputContainer >
						<SpanInput>CPF</SpanInput>
						<Input 
							className="input"
							name="cpf"
							required
							placeholder="Digite o número de cpf"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Identidade</SpanInput>
						<Input 
							className="input"
							name="rg"
							placeholder="Digite a identidade"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Data de Nascimento</SpanInput>
						<Input 
							className="input"
							name="birthdate"
							required
							placeholder="Digite a data de nascimento"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Plano</SpanInput>
						<Input 
							className="input"
							name="plan"
							required
							placeholder="Digite o plano (A OU B)"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Descrição do plano</SpanInput>
						<Input 
							className="input"
							name="planDescription"
							required
							placeholder="Digite a descrição do plano"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Data de Adesão ao Plano</SpanInput>
						<Input 
							className="input"
							name="dtAssociacaoPlanoSaude"
							required
							placeholder="Digite a data de nascimento"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Carteirinha</SpanInput>
						<Input 
							className="input"
							name="carteirinha"
							required
							placeholder="Digite o número da carteirinha"/>
					</InputContainer>

					<InputContainer style={{width: 'calc(30% + 20px)', flexGrow: 0}}>
						<SpanInput>Parcelas adicionais de novo titular</SpanInput>
						<Input 
							className="input"
							name="monthsToIncrease"
							required
							placeholder='Insira o número de parcelas (Ex: 3)'

							/>
					</InputContainer>

					<ButtonOutlined type="submit">{!loading ? 'Cadastrar' : 'Carregando...'}</ButtonOutlined>
				</Unform>

			</Wrapper>
			

		</Container>
	);
}
