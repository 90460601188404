import api from "./api"
import { toast } from "react-toastify"

export const handleCreatePayments = async ({ mutate }) => {
  toast.info("Gerando débitos. Em alguns minutos eles estarão disponíveis.", { autoClose: false })

  const { data } = await api.post("/admin/payments", {}, {
    timeout: 120000
  })

  if(!data.error)
    toast.success("Os pagamentos foram cadastrados com sucesso.")

  if(mutate)
    mutate('/admin/payment/all?limit=2000')
}

export const handleProcessReturn = async ({ mutate }) => {
  toast.info("Processando retornos.", { autoClose: false })

  const { data, status } = await api.post("/admin/retorno", {}, {
    timeout: 120000
  })

  if(status < 300)
    toast.success("Os pagamentos foram sincronizados com sucesso.")


  mutate('/admin/payment/all?limit=2000')
}

export const handleCreateOdonto = async ({ mutate }) => {
  toast.info("Criando débitos para o odonto.", { autoClose: false })

  const { data, status } = await api.post("/admin/payments/odonto", {}, {
    timeout: 120000
  })

  if(status > 201)
    return

  toast.success("Os pagamentos foram criados com sucesso.")

  mutate('/admin/payment/all?limit=2000')

}

export const handleProcessRemessa = async ({ mutate }) => {
  toast.info("Processando Remessa... ", { autoClose: false })

  const { data, status } = await api.post("/admin/remessa", {}, {
    timeout: 120000
  })

  if(status > 201)
  return

  toast.success(data.message || "Sucesso, em breve os boletos estarão disponíveis para pagamento.")

  mutate('/admin/payment/all?limit=2000')

}