import React, { useState} from 'react';
import { isAfter } from 'date-fns'
import Axios from 'axios';
import {
	faTrash,
	faRetweet,
	faFilePdf,
	faEnvelope,
	faSearch,
	faCheck,
	faFileDownload
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataGrid } from '@mui/x-data-grid';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { Form } from '../Titulares/styles';
import Input from '../../Components/Input';
import ListItemButton from '../../Components/ListItemButton';

import Container from '../../Components/PageContainer';
import Topbar from '../../Components/TopBar';

import api, { useFetch } from '../../Services/api'
import { toast } from 'react-toastify';

import Select from 'react-select'

import { useSWRConfig } from 'swr'

const getStatus = ({ date, status }) => {

	if (status === "PAGO") {
		return {
			color: 'primary',
			text: 'Liquidado'
		}
	}

	if (status === "EMITIDO" || status === "AGUARDANDO_ENVIO_REMESSA" || status === "CRIADO") {
		return {
			color: 'blue',
			text: 'Aguardando Remessa'
		}
	}

	if (status === "ENVIADO_VIA_REMESSA") {
		return {
			color: 'deepBlue',
			text: 'Aguardando Processamento'
		}
	}

	if (status === "REJEITADO") {
		return {
			color: 'red',
			text: 'Rejeitado',
		}
	}

	const dateSplit = date.split('/')

	if (isAfter(new Date(), new Date(`${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`))) {
		return {
			color: 'red',
			text: 'Vencido'
		}
	}

	return {
		color: 'waiting',
		text: 'A Vencer'
	}

}

const getQuery = ({ name, status, mes, ano}) => {
	let query = `?limit=3000`

	console.log({ name, status, mes, ano})

	if(name) query += `&name=${name}`
	if(status) query += `&status=${status.value}`
	if(!!mes) query += `&mesReferencia=${mes.value}`
	if(!!ano) query += `&anoReferencia=${ano.value}`

	return query
}

const Payments = () => {
	const [status, setStatus] = useState()
	const [mes, setMes] = useState()
	const [ano, setAno] = useState()
	const [name, setName] = useState('')
	const { data } = useFetch(`/admin/payment/all${getQuery({name, status, mes, ano})}`)

	const { mutate } = useSWRConfig()

	const handlePullPayment = async (idIntegracao) => {
		toast.info("Buscando informações do título junto ao banco...", { autoClose: true })

		try {
			await api.post(`/admin/retorno/${idIntegracao}`, {}, {
				timeout: 120000
			})

			toast.success("Título atualizado com sucesso...")
			mutate('/admin/payment/all?limit=2000')

		} catch (err) {
			toast.error("Ooops. Talvez seja a hora de gritar pelo Francisco.")
		}
	}

	const handleResendEmail = async (paymentNumber) => {
		toast.info("Solicitando reenvio do email...", { autoClose: true })

		try {
			const response = await api.post(`/admin/email/send/${paymentNumber}`, {}, {
				timeout: 120000
			})

			if(response.status > 201) {
				return toast.error(response?.data?.error?.message 
					|| response?.data?.error
					|| 'Erro solicitar reenvio do boleto.'
				)
			}

			toast.success("Email enviado com sucesso...")

		} catch (err) {
			//console.log(err.response)
			toast.error("Ooops. Talvez seja a hora de gritar pelo Francisco.")
		}


	}


	const handleDeletePayment = async ({ userId, paymentId, paymentNumber }) => {
		toast.info(`Excluindo Pagamento ${paymentNumber}...`, { autoClose: false })

		try {
			const { data } = await api.delete(`/admin/payment/${paymentNumber}`, {}, {
				timeout: 120000
			})
			mutate('/admin/payment/all?limit=2000')

			toast.success(data.message || "Sucesso, o boleto foi excluído.")

		} catch (err) {
			toast.error("Ooops. Talvez seja a hora de gritar pelo Francisco.")
		}

		mutate('/admin/payment/all?limit=2000')

	}

	const handleSetAsPaid = async ({ paymentNumber }) => {
		toast.info(`Marcando boleto como pago...`)

		try {
			const { data } = await api.post(`/admin/payment/setAsPaid`, {
				number: paymentNumber
			}, {
				timeout: 30000
			})
			mutate('/admin/payment/all?limit=2000')

			toast.success(data.message || "Sucesso, o boleto foi marcado como pago!")

		} catch (err) {
			toast.error("Ooops. Talvez seja a hora de gritar pelo Francisco.")
		}

		mutate('/admin/payment/all?limit=2000')

	}

	const handleGetDeclarationRelatorioCopariticpacao = async (paymentNumber, userId) => {

		try {

			toast.info("Gerando relatório...")

			const { data } = await Axios.post('https://us-central1-porta-iasp.cloudfunctions.net/GenerateRelatorioCoparticipacao', {
				paymentNumber,
				userId
			}, {
				responseType: 'arraybuffer'
			})
	
			const blob = new Blob([data]);
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download=`RELATORIO_COPARTICIPACAO_${paymentNumber}.PDF`
			link.click()
		} catch(err) {
			toast.error('Não foi possível gerar a declaração')
		}
	}

	const columns = [
		{ field: 'number', headerName: 'Número', width: 80 },
		{
			field: 'titular',
			headerName: 'Titular',
			width: 300,
			valueGetter: (params) => {
				const user = params.getValue(params.id, 'usuario');

				return user ? user.name : 'Sem Titular'
			}
		},
		{
			field: 'value',
			headerName: 'Valor',
			width: 110,
			valueFormatter: ({ value }) => {
				return `R$ ${value}`
			}

		},
		{ field: 'date', headerName: 'Vencimento', width: 110, },
		{
			field: 'status',
			headerName: 'Status',
			width: 200,
			renderCell: (params) => {

				const { color, text } = getStatus({
					date: params.row.date,
					status: params.value
				})


				return !params.row.motivoErro
					? <Chip label={text} color={color} />
					: (
						<Tooltip title={params.row.motivoErro}>
							<Chip label={text} color={color} />
						</Tooltip>
					)
			}
		},
		{
			field: 'type',
			headerName: 'Referência',
			width: 200,
			valueGetter: params => {

				if (!params.value) return "Sem Referência"

				const tipos = {
					"mensalidadeOdonto": "Mensalidade Odontológica",
					"healthPlanMontlhyPayment": "Mensalidade Plano Saúde",
					"healthPlanCoparticipacao": "Coparticipação Plano Saúde",
					"taxaAdesao": "Taxa de Adesão",
					"taxaCarteirinha": "Taxa de Carteirinha",
					"parcelamentoMensalidadeAtrasada": "Parcelamento de Mensalidade",
					"aporteFinanceiro": "Rateio - Reajuste Técnico",
				}

				return tipos[params.value]
			}
		},
		{
			field: 'id',
			headerName: 'Ações',
			width: 200,
			renderCell: (params) => {

				return (
					<div style={{ display: "flex" }}>
						<Tooltip title="Excluir e Baixar Boleto">
							<IconButton onClick={() => handleDeletePayment({ userId: params.row.user.id, paymentId: params.row.number, paymentNumber: params.row.number })}>
								<FontAwesomeIcon icon={faTrash} style={{ fontSize: 20 }} color="#b91646" />
							</IconButton>
						</Tooltip>

						{params.row.status !== "PAGO" && (
							<Tooltip title="Marcar como Pago">
								<IconButton onClick={() => handleSetAsPaid({ paymentNumber: params.row.number })}>
									<FontAwesomeIcon icon={ faCheck } style={{fontSize: 20}} color="#00a76f" />
								</IconButton>
							</Tooltip>
						)}

						{params.row.type === 'healthPlanCoparticipacao' && (
							<Tooltip title="Relatório Coparticipacao">
								<IconButton onClick={() => {
									handleGetDeclarationRelatorioCopariticpacao(params.row.number, params.row.user)
								}}>
									<FontAwesomeIcon icon={faFileDownload} style={{ fontSize: 20 }} color="rgb(20, 110, 190)" />
								</IconButton>
							</Tooltip>
						)}

						{params.row.urlBoleto && (
							<Tooltip title="Visualizar PDF">
								<IconButton onClick={() => {
									window.open(`${params.row.urlBoleto}`)
								}}>
									<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 20 }} color="rgb(20, 110, 190)" />
								</IconButton>
							</Tooltip>
						)}

						{params.row.idIntegracao && (
							<Tooltip title="Atualizar Débito Junto ao Banco">
								<IconButton onClick={() => {
									handlePullPayment(params.row.idIntegracao)
								}}>
									<FontAwesomeIcon icon={faRetweet} style={{ fontSize: 20 }} color="black" />
								</IconButton>
							</Tooltip>
						)}

						{params.row.idIntegracao && (
							<Tooltip title="Enviar Boleto por Email">
								<IconButton onClick={() => {
									handleResendEmail(params.row.number)
								}}>
									<FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 20 }} color="rgb(20, 110, 190)" />
								</IconButton>
							</Tooltip>
						)}

					</div>
				)
			}
		},
	]

	const handleSearch = ({titular}) => {
		setName(titular)
	}

	return (
		<Container>
			<Topbar
				title="Pagamentos" />

			<Form onSubmit={handleSearch}>

	
					<div style={{
							marginLeft: "auto", 
							display: 'flex', 
							flexDirection: 'row',
							alignItems: 'center',
							}}>

						<Select
							options={[
								{ value: '1', label: 'Janeiro' },
								{ value: '2', label: 'Fevereiro' },
								{ value: '3', label: 'Março'},
								{ value: '4', label: 'Abril'},
								{ value: '5', label: 'Maio'},
								{ value: '6', label: 'Junho'},
								{ value: '7', label: 'Julho'},
								{ value: '8', label: 'Agosto'},
								{ value: '9', label: 'Setembro'},
								{ value: '10', label: 'Outubro'},
								{ value: '11', label: 'Novembro'},
								{ value: '12', label: 'Dezembro'}
							]}
							isClearable={true}
							isSearchable={true}
							placeholder="Mês"
							value={mes}
							onChange={setMes}
							menuPortalTarget={document.body}
							styles={{ 
								menuPortal: base => ({ ...base, zIndex: 9999 }),
								control: styles => ({ ...styles, width: 180, flex: 1, marginRight: 5})
							}} />

						<Select
							options={[
								{ value: '2020', label: '2020' },
								{ value: '2021', label: '2021' },
								{ value: '2022', label: '2022'},
								{ value: '2023', label: '2023'},
							]}
							isClearable={true}
							isSearchable={true}
							placeholder="Ano"
							value={ano}
							onChange={setAno}
							menuPortalTarget={document.body}
							styles={{ 
								menuPortal: base => ({ ...base, zIndex: 9999 }),
								control: styles => ({ ...styles, width: 150, flex: 1, marginRight: 20})
							}} />					

						<Select
							options={[
								{ value: 'CRIADO', label: 'Aguardando Remessa' },
								{ value: 'AGUARDANDO_ENVIO_REMESSA', label: 'Aguardando Processamento' },
								{ value: 'PROCESSADO', label: 'Aguardando Pagamento' },
								{ value: 'PAGO', label: 'Liquidado' },
							]}
							isClearable={true}
							isSearchable={true}
							placeholder="Status"
							value={status}
							onChange={setStatus}
							menuPortalTarget={document.body}
							styles={{ 
								menuPortal: base => ({ ...base, zIndex: 9999 }),
								control: styles => ({ ...styles, width: 250, flex: 1, marginRight: 20})
							}} />

						<Input
							name="titular"
							placeholder="Filtrar por nome do titular..."
							style={{width: 300,}}
							 />
					</div>

					<ListItemButton 
						icon={faSearch} 
						type="submit"
						title="Buscar"/>
			</Form>

			{/* <ActionBar>
				<Chip label="A Vencer" color="waiting" className="chip" />
				<Chip label="Estão pagos" color="primary" className="chip"/>
				<Chip label="Estão Vencidos" color="red" className="chip"/>
				<Chip label="Estão aguardando envio de remessa" color="blue" className="chip" />
				<Chip label="Foram enviados via remessa e estão aguardando processamento pelo banco" color="deepBlue" className="chip" />
			</ActionBar> */}


			<DataGrid
				rows={!data ? [] : data.payments}
				columns={columns}
				pageSize={50}
				rowsPerPageOptions={[50]}
				loading={!data}
				getRowId={(row) => row.number}
				localeText={{
					columnMenuFilter: "Filtrar",
					columnMenuSortAsc: 'Ordenar ASC',
					columnMenuSortDesc: 'Ordenar DESC',
					columnMenuHideColumn: 'Ocultar Coluna',
					columnMenuShowColumns: 'Exibir Coluna',
					columnMenuUnsort: 'Cancelar ordenação',
					filterOperatorContains: 'Contém',
					filterOperatorEquals: 'É igual a',
					filterOperatorStartsWith: 'Começa com',
					filterOperatorEndsWith: 'Termina com',
					filterOperatorIs: 'é',
					filterOperatorNot: 'não é',
					filterPanelColumns: 'Coluna',
					filterPanelInputLabel: "Valor",
					filterPanelOperators: "Condição"
				}}
				//checkboxSelection
				style={{ marginBottom: 40 }}
			/>

			{/* <DialogNovoPagamento open={openDialog} handleClose={() => setOpenDialog(false)} /> */}


		</Container>
	)
}



export default Payments;