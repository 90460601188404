import React, {useState, useEffect, useRef} from 'react';

import { 
	Container, 
	Section, 
	SectionTitle, 
	Unform, 
	InputContainer, 
	SpanInput, 
	ButtonOutlined,
	Payment, 
	InfoTitle,
	Info
} from './styles';

import Input from '../Input';

import api from '../../Services/api';

export default function Editpayments({payments, id}) {
	const [ editing, setEditing ] = useState(false)
	const [ list, setList ] = useState(false)
	const [ loading, setLoading ] = useState(false)

	const formRef = useRef()

	useEffect(() => {
		setList(payments)
	}, [payments])

	const createPayment = async (info) => {
		info.father = id

		setEditing(false)
		setLoading(true)

		const { data } = await api.post('/admin/payment', info)

		console.log(data)
		setList(data)
		setLoading(false)
	}

	const deletePayment = async (Payment) => {

		setEditing(false)
		setLoading(true)

		const { data } = await api.delete(`/admin/payment/${id}/${Payment}`)

		console.log(data)
		setList(data)
		setLoading(false)
	}

	const changePayStatus = async (payment) => {

		const body = {  user: payment.user, paymentID: payment._id}
		const { data } = await api.put(`/admin/payment`, body)

		setList(data)
	}

	return (
		<Container>
			<Section>
				<SectionTitle>Pagamentos</SectionTitle>
				{editing && <ButtonOutlined style={{marginRight: 10 }}onClick={() => { setEditing(false) }}>Cancelar</ButtonOutlined>}
				{!editing ? (
					<ButtonOutlined 
						onClick={() => {setEditing(true)}}>
							{!loading ? 'Adicionar' : 'Carregando...'}
					</ButtonOutlined>
				): <ButtonOutlined onClick={() => { formRef.current.submitForm() }}>Enviar</ButtonOutlined>}	
			</Section>

			{editing && (
				<Unform onSubmit={createPayment} ref={formRef}>
					<InputContainer>
							<SpanInput>Valor</SpanInput>
							<Input 
								className="input"
								name="value"
								required
								placeholder="Insira o valor EX: 190.00"/>

							<SpanInput>Insira a data</SpanInput>
							<Input 
								className="input"
								name="date"
								required
								placeholder=""/>
					</InputContainer>

				</Unform>
			)}

			{list && !editing ? (
				<Section style={{flexWrap: 'wrap', display: 'flex'}}>
					{list.map(person => (
						<Payment style={{flexGrow: '1'}} key={person.id}>
							<InfoTitle>Valor</InfoTitle>
							<Info>R$ {person.value}</Info>

							<InfoTitle>Data de Vencimento</InfoTitle>
							<Info>{person.date}</Info>

							<InfoTitle>Número</InfoTitle>
							<Info>{person.number}</Info>

							<InfoTitle>Status</InfoTitle>
							<Info>{person.payStatus ? 'Pago' : 'Pendente'}</Info>
							

							<Info onClick={() => { deletePayment(person._id) }} style={{marginLeft: 'auto'}}>Excluir</Info>
							<a href={person.UrlBoleto ? person.UrlBoleto : `http://boletos.portaliasp.org/${person.number}.pdf`} target='_blank'  style={{marginLeft: 'auto'}}>
								<Info  style={{marginLeft: 'auto'}}>Ver PDF</Info>
							</a>
								{!person.payStatus && <Info onClick={() => changePayStatus(person)} style={{marginLeft: 'auto'}}>Marcar como pago</Info>}
		
						</Payment>
						
					))}
				</Section>
			) : !editing &&(
				<Payment>
						<Info>Este titular ainda não tem Paymentes cadastrados.</Info>
				</Payment>
			)}

		</Container>
	);
}
