import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  cpf: Yup.string().min(11).required('CPF é obrigatório'),
  rg: Yup.string().required('RG é obrigatório'),
  birthdate: Yup.string().required('A data de nascimento é obrigatória'),
  email: Yup.string().email().required('O email é obrigatório'),
  instituicaoEmpregadora: Yup.string().optional(),
  campusInstituicaoEmpregadora: Yup.string().optional(),
  telefone: Yup.string().min(11).required('O Telefone é obrigatório'),
  convenio: Yup.string().optional(),
  address: Yup.object().shape({
    cep: Yup.string().min(8).required('CEP é obrigatório'),
    number: Yup.number().integer().required('O número é obrigatório.').typeError('É obrigatório'),
    full: Yup.string().required('O logradouro é obrigatório'),
    neighborhood: Yup.string().required('O Bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    uf: Yup.string().required('O estado é obrigatório'),
    complemento: Yup.string(),
  }),
  shouldPayAssociationFee: Yup.boolean().required('T'),
  monthsToIncrease: Yup.number().default(0).typeError('Deve conter um valor ou ser 0'),
  hasOdonto: Yup.boolean().required(),
  valueOdonto: Yup.number().default(0).typeError('Deve conter um valor ou ser 0'),
  dtAssociacao: Yup.string().nullable(),
  dtAssociacaoOdonto: Yup.string().nullable(),
  dtAssociacaoPlanoSaude: Yup.string().nullable(),
});

export default schema