import React from 'react';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background-color: var(--background-color);
  padding: 40px;
  overflow: scroll;

  h2 {
    color: var(--main-color);
    font-size: 25px;
    font-family: 'Archivo', sans-serif;
    /* text-transform: capitalize !important; */
  }

  .accordion {
    color: var(--main-color);
    font-weight: bold !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 20px;
    
    box-shadow: 0 2px 4px 0 var(--main-color), 0 3px 10px 0 var(--transparency-main-color);
  }
`;


function PageContainer({ children }) {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default PageContainer;