import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Drawer,SwipeableDrawer } from '@material-ui/core'

export const Container = styled(SwipeableDrawer)``

// export const Container = styled.div`
// 	background: #fff;
// 	display: flex;
// 	flex-direction: column;
// 	height: 100%;
// 	width: 325px !important;
// 	position: fixed;
// 	-webkit-box-shadow: 10px 10px 19px -6px rgba(0,0,0,0.23);
// 	-moz-box-shadow: 10px 10px 19px -6px rgba(0,0,0,0.23);
// 	box-shadow: 10px 10px 19px -6px rgba(0,0,0,0.23);
// `

export const Top = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
`

export const UserImage = styled.img`
	width: 70px;
	height: 70px;
	border-radius: 60px;
	border: 2px solid #3C5568;
	padding: 1px;
	
`

export const UserName = styled.h2`
	color: #000;
	font-family: 'Montserrat', sans-serif;
	font-size: 24px;
	font-weight: 400;
	
`

export const PageGroupTitle = styled.span`
	color: #E4AA3A;
	font-family: 'Open Sans', serif;
	font-size: 15px;
	margin-top: 30px;
	font-style: italic;
`

export const Page = styled(Link)`
	display: flex;
	cursor: pointer;
	color: #fafafa;
	font-family: 'Open Sans', serif;
	font-weight: bold;
	font-size: 16px;
	margin-left: 20px;
	margin-top: 10px;
`