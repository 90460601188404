import styled from 'styled-components';
import { Link as LinkRef } from 'react-router-dom';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
`

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin-left: 325px;
`

export const Toolbar = styled.div`
	display: flex;
	padding: 50px 50px 0px 50px;
	align-items: center;
`

export const SectionTitle = styled.span`
	color: var(--main-color);
	font-size: 25px;
`

export const Right = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`

export const TextButton = styled(LinkRef)`
	color: rgba(255,255,255, 0.7);
	font-size: 20px;
	cursor: pointer;
	margin-right: 20px;

	:hover{
		color: rgba(255,255,255, 1);
	}
`

export const List = styled.div`
	width: calc(100% - 30px);
	display: flex;
	margin-top: 30px;
	margin-left: 30px;
	flex-wrap: wrap;
`