import React, {useState, useEffect, useRef} from 'react';

import { 
	Container, 
	Section, 
	SectionTitle, 
	Unform, 
	InputContainer, 
	SpanInput, 
	ButtonOutlined,
	Dependent, 
	InfoTitle,
	Info
} from './styles';

import Input from '../Input';

import api from '../../Services/api';

export default function EditDependents({dependents, id}) {
	const [ editing, setEditing ] = useState(false)
	const [ list, setList ] = useState(false)
	const [ loading, setLoading ] = useState(false)

	const formRef = useRef()

	useEffect(() => {
		setList(dependents)
	}, [dependents])

	const createDependent = async (info) => {
		info.father = id

		setEditing(false)
		setLoading(true)

		const { data } = await api.post('/admin/dependent', info)

		console.log(data)
		setList(data)
		setLoading(false)
	}

	const deleteDependent = async (dependent) => {

		setEditing(false)
		setLoading(true)

		const { data } = await api.delete(`/admin/dependent?id=${dependent}&father=${id}`)

		console.log(data)
		setList(data)
		setLoading(false)
	}

	return (
		<Container>
			<Section>
				<SectionTitle>Dependentes</SectionTitle>
				{editing && <ButtonOutlined onClick={() => { setEditing(false) }}>Cancelar</ButtonOutlined>}
				{!editing ? (
					<ButtonOutlined 
						onClick={() => {setEditing(true)}}>
							{!loading ? 'Adicionar' : 'Carregando...'}
					</ButtonOutlined>
				): <ButtonOutlined onClick={() => { formRef.current.submitForm() }}>Enviar</ButtonOutlined>}	
			</Section>

			{editing && (
				<Unform onSubmit={createDependent} ref={formRef}>
					<InputContainer>
							<SpanInput>Nome</SpanInput>
							<Input 
								className="input"
								name="name"
								required
								placeholder="Insira o nome completo"/>
					</InputContainer>

					<InputContainer>
							<SpanInput>Data de Nascimento</SpanInput>
							<Input 
								className="input"
								name="birthdate"
								required
								placeholder="Insira a data de nascimento"/>
					</InputContainer>
				</Unform>
			)}


			
			{list && !editing ? (
				<Section style={{flexWrap: 'wrap'}}>
					{list.map(person => (
						<Dependent key={person._id}>
							<InfoTitle>Nome</InfoTitle>
							<Info>{person.name}</Info>

							<InfoTitle>Data de Nascimento</InfoTitle>
							<Info>{person.birthdate}</Info>

							<Info onClick={() => { deleteDependent(person._id) }} style={{marginLeft: 'auto'}}>Excluir</Info>
						</Dependent>
						
					))}
				</Section>
			) : !editing &&(
				<Dependent>
						<Info>Este titular ainda não tem dependentes cadastrados.</Info>
				</Dependent>
			)}
		</Container>
	);
}
