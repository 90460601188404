import Modality from '../assets/modalities'

function simulate(user) {
	const { plan, planDescription, age } = user
		let price = 0
		const common = 'UNIMED OURO NACIONAL COLETIVO POR ADESAO'
		const c50pool = 'COMPANY - 50 NACIONAL  ADESÃO COLETIVO'
		const type = ['A', 'B']
		let modality = ''

		if(planDescription === common && plan === type[0]) {
			modality = 'goldnacionalA'
		} else if (planDescription === common && plan === type[1]) {
			modality = 'goldnacionalB'
		} else if (planDescription === c50pool) {
			modality = 'c50pool'
		} else {
			modality = 'c50star'
		}
		
		const a = Modality.find(modality => modality.ageMin <= age && age <= modality.ageMax)
		price = price + a[modality]
	
		
		return price.toFixed(2)
}

export default simulate