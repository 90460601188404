import axios from 'axios';
import useSWR from "swr";

const api = axios.create({
    baseURL: 'https://iasp-db.hasura.app/v1/graphql',
    headers: {
        'x-hasura-admin-secret': 'YExz687ZmhR2dPBhVlF5Gwwf0zqtd2h8poDAhP17Ts4p5HSrqzm2rwWDe1q7wxqV'
    }
})

export const useFetch = (url) => {
    return useSWR(url, async (url) => {
        const response = await api.get(url)
        return response.data
    })
}


export default api;