import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;

  h2 {
    margin-bottom: 0px !important;
    color: var(--main-color);
    font-size: 25px;
    font-family: 'Archivo', sans-serif;
    /* text-transform: capitalize !important; */
  }
`;
