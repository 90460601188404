import styled from 'styled-components'

import { Link as Route } from 'react-router-dom'

export const Container = styled(Route)`
	height: 95px;
	display: flex;
	cursor: pointer;
	align-items: center;
	padding-left: 30px;
	padding-right: 20px;
	transition: all 300ms;
	border: 1px solid #ffffff;
	:hover {
		background: rgb(243,243,243);
		border: 1px solid rgba(0,0,0,0.1);
	}
	
`

export const Page = styled.span`
	font-family: 'Montserrat', sans-serif;
	color: #A0A0A0;
	font-size: 22px;
	margin-left: 20px;
	
`