
import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import TextField from '@mui/material/TextField'

const styles = {
  flexGrow: 1,
  margin: "10px 5px"
}


export default function Input({ name, mask, ref, style, ...rest }) {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value || ''
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return <TextField
    error={!!error}
    helperText={!!error ? error : ""}
    inputRef={inputRef} 
    defaultValue={defaultValue} 
    InputLabelProps={{ shrink: true  }}
    {...rest}
    mask={mask}
    style={{
      ...styles, ...style
    }}
    
   />
}

