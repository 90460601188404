import React from 'react';

import { 
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'

import ListItemButton from '../ListItemButton'

import { Container } from './styles';

function TopBar({ title, history, showBackButton }) {
  return (
    <Container>
      {showBackButton && (
        <ListItemButton 
        icon={faArrowLeft}
        title="Voltar"
        onClick={() => { history.goBack()}} />
      )}

      <h2>{title}</h2>
    </Container>

  );
}

export default TopBar;