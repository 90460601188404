import React from 'react';

import { 
	Container, 
	Wrapper, 
	Toolbar, 
	SectionTitle, 
	Unform,
	InputContainer,
	SpanInput,
	ButtonOutlined
} from './styles'


import Input from '../../Components/Input'
import TextArea from '../../Components/TextArea'
import SideMenu from '../../Components/SideMenu'
import InputFile from '../../Components/InputFile'
import SelectPicker from '../../Components/SelectPicker'

import api from '../../Services/api'

const states = [
	{
    value: 'TO',
    label: 'TO'
  }
]

const cities = [
	{
    value: 'Araguaína',
    label: 'Araguaína'
	},
	{
    value: 'Araguatins',
    label: 'Araguatins'
	},
	{
    value: 'Colinas do Tocantins',
    label: 'Colinas do Tocantins'
	},
	{
    value: 'Dianópolis',
    label: 'Dianópolis'
	},
	{
    value: 'Formoso do Araguaia',
    label: 'Formoso do Araguaia'
	},
	{
    value: 'Gurupi',
    label: 'Gurupi'
	},
	{
    value: 'Lagoa da Confusão',
    label: 'Lagoa da Confusão'
	},
	{
    value: 'Palmas',
    label: 'Palmas'
	},
	{
    value: 'Paraíso do Tocantins',
    label: 'Paraíso do Tocantins'
	},
	{
    value: 'Pedro Afonso',
    label: 'Perdro Afonso'
	},
	{
    value: 'Porto Nacional',
    label: 'Porto Nacional'
	},
	
	
]

function NewBenefit({history}) {

	async function handleSubmitForm(form) {

		console.log(form)
		const formData = new FormData()

		formData.append('title', form.title)
		formData.append('description', form.description)
		formData.append('details', form.details)
		formData.append('city', form.city)
		formData.append('state', form.state)
		formData.append('address', form.address)
		formData.append('phone', form.phone)
		formData.append('logo', form.file)
		
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		const { data } = await api.post('/admin/benefit', formData, config)

		if(!data.error) alert('Erro ao cadastrar convênio')

		history.goBack()

	}
	return (
		<Container>
			<SideMenu />

			<Wrapper>
				<Toolbar>
					<SectionTitle>
						Novo Convênio
					</SectionTitle>
				</Toolbar>

				<Unform onSubmit={handleSubmitForm}>
					<InputContainer style={{width: '100%'}}>
						<SpanInput>Título</SpanInput>
						<Input 
							className="input"
							name="title"
							required={true}
							placeholder="Insira um título (Ex: Nome do estabecimento)"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Descrição</SpanInput>
						<Input 
							className="input"
							name="description"
							required
							placeholder="Insira uma descrição curta sobre o benefício"/>
					</InputContainer>

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Telefone</SpanInput>
						<Input 
							className="input"
							name="phone"
							required={true}
							placeholder="Insira o telefone do parceiro"/>
					</InputContainer>

					{/* PICKER */}
					<InputContainer style={{width: '100%'}}>
						<SpanInput>Estado</SpanInput>
						<SelectPicker
							options={states}
							className="input"
							name="state"
							required={true}
							placeholder="Selecione um estado"/>
					</InputContainer>

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Cidade</SpanInput>
						<SelectPicker
							options={cities}
							name="city"
							required={true}
							placeholder="Selecione uma cidade"/>
					</InputContainer>

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Endereço</SpanInput>
						<Input 
						className="input"
							name="address"
							required={true}
							placeholder="Insira o endereço do parceiro"/>
					</InputContainer>
				

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Detalhes</SpanInput>
						<TextArea
							className="input"
							name="details"
							type="textarea"
							style={{height: '200px'}}
							placeholder="Insira mais detalhes sobre o convênio (opcional)"/>
					</InputContainer>

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Logomarca do parceiro</SpanInput>
						<InputFile
							className="input"
							name="file"
							required={true}
							placeholder="Insira um título (Ex: Nome do estabecimento)"/>
					</InputContainer>

					<ButtonOutlined>Cadastrar</ButtonOutlined>
				

					</Unform>
			</Wrapper>
		</Container>
	);
}

export default NewBenefit;