import styled from 'styled-components';

export const Container = styled.input`
	font-family: 'Bree Serif', serif;
	font-size: 18px;
	padding: 10px 0 10px 0;
	color:  #3cba92;
	background: none;
	display: flex;
	max-height: 100px;
`;
