import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.location.pathname === "/" && props.vertical)  ? "none" : "flex"};
  width: ${props => props.vertical ? "" : "100%"};
  padding: ${props => props.vertical ? "20px" : "0px"};
  border-right: ${props => props.vertical ? "2px solid var(--transparency-main-color)" : "none"};
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: ${props => props.vertical ? 'column' : 'row'};
  position: fixed;
  z-index: 1000;
  height: 100%;
  background-color: var(--highlight-color);
`;
