import React from 'react';

import { Container } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Badge } from '@material-ui/core'

function ListItemButton({ icon, title, isBadgeVisible, type, ...rest }) {
  return (
    <Tooltip title={title}>
      <Badge color="primary" variant="dot" invisible={!isBadgeVisible} style={{marginRight: 10}}>
        <Container {...rest} type={type || "button"}>
          <FontAwesomeIcon icon={ icon } size={"2x"} />
        </Container>
      </Badge>
    </Tooltip>
  );
}

export default ListItemButton;