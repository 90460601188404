import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const Container = styled.div`
  width: calc(100% - 40px);
  height: 70px;
  background-color: var(--main-color);
  display: ${props => props.display ? 'flex' : 'none'};
  align-items: center;
  padding: 0px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  span {
    margin-left: 10px;
  }

  @keyframes hover-menu {
    0% {transform: rotate(0deg); opacity: 1;}
    50% {}
    100% {transform: rotate(0deg); opacity: 1;}
  }

  h2 {
    color: var(--highlight-color);
    font-size: 25px;
    font-family: 'Archivo', sans-serif;
  }

  #page-groups {
    margin-left: 40px;
  } 

  #page-options {
    margin-left: auto;
    margin-right: 20px;
    align-items: center;
    justify-content: center;

    
  }

  button {
    background: none;
    margin-right: 20px;
    cursor: pointer;
    color: var(--highlight-color);
    font-weight: bold;


    :hover {
      background-color: green;
    }
  }

`;

export const ActionSpan = styled.span`
  margin-left: 10px;
`
const options = {
  shouldForwardProp: (prop) => prop !== 'fontColor',
};

export const SearchInput = styled.input`
  color: #fff;
  background: none;
  height: calc(100% - 7px);
  margin: 10px 10px;
  width: 300px;
  padding-bottom: 7px;

  font-size: 16px;
  font-weight: bold;

  border-bottom: 1px solid rgba(255, 255, 255, 0.6);

  ::placeholder {
    color: #fff;
  }
  
`

