import React, { useRef, useState } from 'react';

import { Container, Toolbar, SectionTitle, Unform, InputContainer, SpanInput, ButtonOutlined, Wrapper } from './styles';

import Input from '../../Components/Input';

import SideMenu from '../../Components/SideMenu';
import api from '../../Services/api';


export default function Notification({history}) {
	const [ loading, setLoading ] = useState(false)
	const [ error, setError ] = useState(false)
	const formRef = useRef()

	const submitForm = async (info) => {
		setLoading(true)

		info.sendToAllUsers = true

		const { data } = await api.post('/admin/notify', info)

		if(data.error) alert('Erro ao disparar notificação')

		setLoading(false)
	}

	return (
		<Container>
			<SideMenu />


			<Wrapper>
				<Toolbar>
					<SectionTitle>Nova notificação</SectionTitle>
					
				</Toolbar>

				<Unform onSubmit={submitForm} ref={formRef}>

					<InputContainer style={{width: '100%'}}>
						<SpanInput>Título</SpanInput>
						<Input 
							className="input"
							name="title"
							required={true}
							placeholder="Digite o título da notificação"/>
					</InputContainer>
					

					<InputContainer >
						<SpanInput>Descrição</SpanInput>
						<Input 
							className="input"
							name="description"
							placeholder="Digite a descrição da notificação"/>
					</InputContainer>

					<InputContainer >
						<SpanInput>Link</SpanInput>
						<Input 
							className="input"
							name="link"
							placeholder="Digite o link para redirecionamento"/>
					</InputContainer>

					<ButtonOutlined type="submit">{!loading ? 'Cadastrar' : 'Carregando...'}</ButtonOutlined>
				</Unform>

			</Wrapper>
			

		</Container>
	);
}
