import React, {useState, useRef, useEffect} from 'react';

import { Scope } from '@unform/core';

import {
	Container,
	// Section, 
	// SectionTitle, 
	Unform, 
	InputContainer, 
	SpanInput, 
	// ButtonOutlined,
} from './styles';

import Input from '../Input';

import api from '../../Services/api';


export default function EditUser({id, user, history}) {
	const [ editing, setEditing ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const [ remove, setRemove ] = useState(false)

	const formRef = useRef()

	useEffect(() => {
		formRef.current.setData(user)
	}, [user])

	const updateUserData = async (info) => {
		info.id = id

		setEditing(false)
		setLoading({user: true})
		const { data } = await api.put('/admin/user', info)

		if(!data.error) formRef.current.setData(data)

		if(data.error) formRef.current.setData(user)

		setLoading(false)
	}

	const deleteUser = async () => {
		if(!remove) {
			setRemove(true)
		} else {
			await api.delete(`/admin/user/${id}`)
			history.replace('/admin/users')
		}
		
	}

	return (
	<Container>
		{/* <Section>
			<ButtonOutlined  onClick={deleteUser}>{!remove ? 'Excluir' : 'Confirmar exclusão'}</ButtonOutlined>
			{!editing ? (
				<ButtonOutlined 
					className="margin-l"
					onClick={() => {setEditing(true)}}>
						{!loading ? 'Editar' : 'Carregando...'}
				</ButtonOutlined>
			): <ButtonOutlined className="margin-l" onClick={() => { formRef.current.submitForm() }}>Salvar</ButtonOutlined>}	
		</Section> */}

		<Unform onSubmit={updateUserData} ref={formRef}>
			<InputContainer style={{width: '50%'}}>
				<SpanInput>Nome</SpanInput>
				<Input 
					className="input"
					name="name"
					required
					disabled={!editing}/>

			</InputContainer>

			{/* <InputContainer style={{width: '50%'}}>
				<SpanInput>Descrição do Plano</SpanInput>
				<Input 
					className="input"
					name="planDescription"
					required
					disabled={!editing}/>
			</InputContainer> */}
			
			<InputContainer >
				<SpanInput>CPF</SpanInput>
				<Input 
					className="input"
					name="cpf"
					required
					disabled={!editing}/>
			</InputContainer>

			<InputContainer >
				<SpanInput>Identidade</SpanInput>
				<Input 
					className="input"
					name="rg"
					required
					disabled={!editing}/>
			</InputContainer>

			<InputContainer>
				<SpanInput>Data de nascimento</SpanInput>
				<Input 
					className="input"
					name="birthdate"
					required
					disabled={!editing}/>
			</InputContainer>

			<InputContainer >
				<SpanInput>Carteirinha</SpanInput>
				<Input 
					className="input"
					name="carteirinha"
					required
					disabled={!editing}/>
			</InputContainer>

			{/* <InputContainer>
				<SpanInput>Plano</SpanInput>
				<Input 
					className="input"
					name="plan"
					required
					disabled={!editing}/>
			</InputContainer> */}


			<InputContainer>
				<SpanInput>Email</SpanInput>
				<Input 
					className="input"
					name="email"
					required
					disabled={!editing}/>
			</InputContainer>

			<InputContainer >
				<SpanInput>Parcelas adicionais</SpanInput>
				<Input 
					className="input"
					name="monthsToIncrease"
					required
					disabled={!editing}/>
			</InputContainer>

			<Scope path="address">
				<InputContainer>
					<SpanInput>Endereço</SpanInput>
					<Input 
						className="input"
						name="full"
						required
						disabled={!editing}/>
				</InputContainer>

				<InputContainer>
					<SpanInput>Bairro</SpanInput>
					<Input 
						className="input"
						name="neighborhood"
						required
						disabled={!editing}/>
				</InputContainer>

				<InputContainer>
					<SpanInput>Número</SpanInput>
					<Input 
						className="input"
						name="number"
						required
						disabled={!editing}/>
				</InputContainer>

				<InputContainer>
					<SpanInput>CEP</SpanInput>
					<Input 
						className="input"
						name="cep"
						required
						disabled={!editing}/>
				</InputContainer>

				<InputContainer>
					<SpanInput>Cidade</SpanInput>
					<Input 
						className="input"
						name="city"
						required
						disabled={!editing}/>
				</InputContainer>

				<InputContainer>
					<SpanInput>UF</SpanInput>
					<Input 
						className="input"
						name="uf"
						required
						disabled={!editing}/>
				</InputContainer>

			</Scope>
		</Unform>
	</Container>
	);
}
