const date = new Date()
const mesReferencia = Number(date.getMonth() + 1)
const anoReferencia = Number(date.getFullYear())

console.log(mesReferencia)
console.log(anoReferencia)

export const TODOS = (dateSelected) => (`
  query Todos {
    Payment_aggregate(where: {
      mesReferencia: {
        _eq: ${dateSelected.mesReferencia}
      },
      anoReferencia: {
        _eq: ${dateSelected.anoReferencia}
      },
      dtExclusao: {
        _is_null: true
      },
      status: {
        _neq: "REJEITADO"
      },
    }) {
      aggregate {
        count,
        sum {
          value
        }
      }
    }
  }
`)

export const PAGOS = (dateSelected) => (`
query Todos {
  Payment_aggregate(where: {
    mesReferencia: {
      _eq: ${dateSelected.mesReferencia}
    },
    anoReferencia: {
      _eq: ${dateSelected.anoReferencia}
    },
		status: {
			_eq: "PAGO"
		},
		dtExclusao: {
			_is_null: true
		}
  }) {
    aggregate {
      count,
      sum {
        value
      }
    }
  }
	
}`)

export const VENCIDOS = (dateSelected) => (`
  query Todos {
    Payment_aggregate(where: {
      mesReferencia: {
        _eq: ${dateSelected.mesReferencia}
      },
      anoReferencia: {
        _eq: ${dateSelected.anoReferencia}
      },
      status: {
        _eq: "PROCESSADO"
      },
      dtExclusao: {
        _is_null: true
      }
    }) {
      aggregate {
        count,
        sum {
          value
        }
      }
    }
    
  }
`)