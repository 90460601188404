import styled from 'styled-components';
import { Form as Unform } from '@unform/web';


export const Beneficiario = styled.section`
	padding: 15px 0px;
	margin: 5px;
	border-radius: 20px;
	padding: 20px;
	max-height: 60px;
	display: flex;
	flex-grow: 1;
	align-items: center;
	background-color: var(--transparency-main-color);
  

	h1 {
		margin: 0;
		font-size: 40px;
		color: var(--main-color);
	}

	div {
		margin-left: 20px;

		h3 {
			color: var(--main-color);
			font-size: 22px;
			margin: 0;
		}

		h2 {
			color: var(--title-color);
			opacity: 0.6;
			font-size: 18px;
			margin: 5px 0 0 0;
		}
	}
`

export const ButtonList = styled.div`
  margin-left: auto !important;
  display: flex;
`

export const Form = styled(Unform)`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  margin-top: 0px;

  a {
    display: flex;
    margin-left: auto;
  }

  .field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 350px;
    margin-right: 20px;

    span {
      color: var(--main-color);
      font-weight: bold;
    }

    .select {
      margin-top: 10px;
      max-width: 200px;
    }

    .date {
      margin-top: 10px;
      position: relative;
      box-sizing: border-box;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      height: 38px;
      font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen', 'Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue','Poppins', sans-serif;
      -webkit-font-smoothing: antialiased;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`


