import styled from 'styled-components';

export const Container = styled.section`
  width: 600px;
  display: flex;
  flex-direction: column;

  header {
    background-color: #00a76f;
    height: 70px;
    padding: 30px;

    h3 {
      color: #fafafa;
      font-size: 18px;
      opacity: 0.9;
      font-style: italic;
      margin: 0;
    }

    a{
      color: #fafafa;
      font-size: 18px;
      font-weight: bold;
      opacity: 0.9;
      font-style: italic;
      margin: 0;
      text-decoration: none;
    }

    h1 {
      color: #fafafa;
      margin: 0 0 10px 0;
      margin-bottom: 5px;
    }

    div {
      display: flex;
      flex-direction: column;

      span {
        color: var(--highlight-color);
        font-size: 20px;
        opacity: 0.9;
      }
    } 
  }

  main {
    padding-left: 20px;
    padding: 10px 30px;
    max-height: 300px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 7px; /* width of the entire scrollbar */
    }
    ::-webkit-scrollbar-thumb {
      background-color: #00a76f; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */ /* creates padding around scroll thumb */
    }

    h1 {
      color: #00a76f;
    }

    section, span {
      margin-top: 10px;
      border-radius: 20px;
      padding: 20px;
      height: 25px;
      display: flex;
      align-items: center;
     

      h1 {
        margin: 0;
        font-size: 30px;
        color: #00a76f;
      }

      div {
        margin-left: 20px;

        h3 {
          color: #00a76f;
          font-size: 20px;
          margin: 0;
        }

        h2 {
          color: #3f3d56;
          opacity: 0.6;
          font-size: 18px;
          margin: 5px 0 0 0;
        }
      }
    }
  }
`;

export const Beneficiario = styled.section`
	padding: 15px 0px;
	margin: 5px;
	border-radius: 20px;
	padding: 20px;
	max-height: 60px;
	display: flex;
	flex-grow: 1;
	align-items: center;
	background-color: rgba(0, 167, 111, 0.1);
    

	h1 {
		margin: 0;
		font-size: 40px;
		color: #00a76f;
	}

	div {
		margin-left: 20px;

		h3 {
			color: #00a76f;
			font-size: 22px;
			margin: 0;
		}

		h2 {
			color: #3f3d56;
			opacity: 0.6;
			font-size: 18px;
			margin: 5px 0 0 0;
		}
	}
`

export const ButtonList = styled.div`
  margin-left: auto !important;
  display: flex;
`
