import { createTheme } from '@mui/material/styles';

const theme = createTheme({
 palette: {
   primary: {
    main: "#00a76f",
    contrastText: "#FFF"
   },
   white: {
    contrastText: "#fff",
    main: "#FFF" 
   },
   waiting: {
     main: '#eed6c4',
     contrastText: "#6b4f4f"
   },
   blue: {
     main: '#344cb7',
     contrastText: "#e6e6e6"
   },
   red: {
    main: '#ff5c58',
    contrastText: "#ffedd3"
   },
   deepBlue: {
    main: '#142f43',
    contrastText: "#e6e6e6"
   }
 }
});

export default theme