/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { 
  faInfo,
  faSearch,
	faPlus,
	faTrash,
	faUsers,
	faUserPlus,
	faFilePdf,
	faFileInvoice
} from '@fortawesome/free-solid-svg-icons';

import { Skeleton } from "@material-ui/lab";
import Select from 'react-select';

import { Beneficiario, ButtonList,  Form  } from './styles';

import Container from '../../Components/PageContainer';
import Input from '../../Components/Input';
import ListItemButton from '../../Components/ListItemButton';

import api from '../../Services/api'
import { toast } from 'react-toastify';

import UserDialog from '../../Components/Dialogs/Associado'
import DependentsDialog from '../../Components/Dialogs/Dependentes'
import AdicionarDependenteDialog from '../../Components/Dialogs/AdicionarDependente'
import Axios from 'axios';

const functionAdhesionDeclarationURL = 'https://us-central1-porta-iasp.cloudfunctions.net/GenerateDeclarationAdhesion'
const functionAdhesionIRPFURL = 'https://us-central1-porta-iasp.cloudfunctions.net/GenerateDeclarationIRPF'


const capitalize = (string) => {
	const stringLowercase = string.toLowerCase()
	return stringLowercase.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
} 

function Dashboard({ history }) {
	const [users, setUsers] = useState([])
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const dialogUserRef = useRef(null)
	const dialogDependentsRef = useRef(null)
	const dialogAddDependentsRef  = useRef(null)

	const getUserList = async (formData = {}) => {

		setLoading(true)
		const { data } = await api.get(!!formData.titular 
			? `/admin/users?name=${formData.titular}`
			: `/admin/users`)

		if(data.error) {
			console.log(data.error)
			setLoading(false)
			return setError(true)
		}
		console.log(data)

		setUsers(data)
		setLoading(false)
	}

	const handleDelete =	async (user) => {
		toast.info("Exluindo associado...")

		try {
			await api.delete(`/admin/user/${user.id}`)
			toast.success('Associado excluído com sucesso')
			getUserList({})
		} catch(err) {
			toast.error('Não foi possível deletar o Associado')
		}
	}
	const handleGetDeclaration = async (user) => {

		try {

			toast.warn("Atenção: a declaração será gerada mesmo que o associado tenha pagamentos pendentes, verifique antes de enviar!!", {autoClose: false})
			toast.info("Gerando declaração...")
			

			const { data } = await Axios.post(functionAdhesionDeclarationURL, {
				UserId: user.id,
				dontSendViaEmail: true
			}, {
				responseType: 'arraybuffer'
			})
	
			const blob = new Blob([data]);
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download=`DECLARACAO_ADESAO_${user.name}.PDF`
			link.click()
		} catch(err) {
			toast.error('Há dados pendentes no cadastro desse associado, reveja e tente novamente.')
		}
	}

	const handleGetDeclarationIRPF = async (user) => {

		try {

			console.log('user', user)
			//toast.warn("Atenção: a declaração será gerada mesmo que o associado tenha pagamentos pendentes, verifique antes de enviar!!", {autoClose: false})
			toast.info("Gerando declaração...")
			

			const { data } = await Axios.post(functionAdhesionIRPFURL, {
				cpf: user.cpf,
				dontSendViaEmail: true
			}, {
				responseType: 'arraybuffer'
			})
	
			const blob = new Blob([data]);
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download=`DECLARACAO_IRPF_PLANO_SAUDE_${user.name}.PDF`
			link.click()
		} catch(err) {
			toast.error('Não foi possível gerar a declaração')
		}
	}

	useEffect(() => {
		document.title = "Associados"
		getUserList()
	}, []) 

	return (
		<Container>

			<UserDialog ref={dialogUserRef} />
			<DependentsDialog ref={dialogDependentsRef} />
			<AdicionarDependenteDialog ref={dialogAddDependentsRef} />

			<h2 className="title">{!error ? `Associados` : 'Não foi possível buscar os Associados devido a uma falha do sistema.'}</h2>
			<Form onSubmit={getUserList}>

					{/* <div className="field">
						<Select
							className="select"
							defaultValue={{ value: 'all', label: 'Todos os Serviços'}}
							options={selectPlans} />
					</div> */}

					<div className="field" style={{marginLeft: "auto"}}>
						<Input
							name="titular"
							label="Nome do Titular"
							placeholder="Filtrar por nome do titular..."
							 />
					</div>

					<ListItemButton 
						icon={faSearch} 
						type="submit"
						title="Buscar"/>
			</Form>


			{
				loading ? (
					<>
						<Skeleton variant="rectangular" height={80} className="skeleton" />
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
						<Skeleton variant="rectangular" height={80} className="skeleton"/>
					</>
			) : users.map(user => (
				
					<Beneficiario key={user.name}>

						<div>
							<h3>{capitalize(user.name)}</h3>
						</div>

						<ButtonList isVisible={"aa"}>
							<ListItemButton 
								icon={faInfo} 
								title="Informações do Beneficiário"
								onClick={() => {
									dialogUserRef.current.handleOpen({
										associado: user
									})
								}}/>


							<ListItemButton 
								icon={faUsers} 
								title="Dependentes"
								onClick={() =>{ dialogDependentsRef.current.handleOpen({ user })}}/>

							<ListItemButton 
								icon={faUserPlus} 
								title="Adicionar Dependente"
								onClick={() =>{ dialogAddDependentsRef.current.handleOpen({user})}}/>

							<ListItemButton 
								icon={faFilePdf} 
								title="Declaração de Adesão"
								onClick={async () =>{ await handleGetDeclaration(user)}}/>

							<ListItemButton 
								icon={faFileInvoice} 
								title="Declaração IRPF - Unimed"
								onClick={async () =>{ await handleGetDeclarationIRPF(user)}}/>

							<ListItemButton 
								icon={faTrash} 
								title="Excluir"
								onClick={() =>{handleDelete(user)}}/>

							
							
						</ButtonList>
					</Beneficiario>
				))
			}
		</Container>
	);
}



export default Dashboard;