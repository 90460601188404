import React, { useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { 
	Container,
	Content,
	Logo,
	Unform,
	Title, 
	Divider, 
	InputContainer,
	SpanInput,
	Button,
	Error
} from './styles';


import Input from '../../Components/Input';
import api from '../../Services/api';
import * as actions from '../../store/actions/login';

import logoSrc from '../../assets/logo.png'

function Login({history, storeUser}) {
	const [ loading, setLoading ] = useState(false)
	const [ error, setError] = useState(null)
	const [ firstAccess, setFirstAcess ] = useState(false)


	const request = (form) => {
		if(!firstAccess) return loginRequest(form)

		firstAccessRequest(form)
	}

	const firstAccessRequest = async (form) => {
		setLoading(true);

		const { data } = await api.post('/auth/first', form);
		
		if(!data.error) {
			storeUser(data)
			return history.push('/titulares')
		}
			

		setError(data.error);
		setLoading(false);
	}

	const loginRequest = async (form) => {
		setLoading(true);

		const response = await api.post('/auth/', form);
		console.log(response)

		const { data } = response
		
		if(!data.error && response.status === 200) {
			storeUser(data)
			return history.replace('/dashboard')
		}

		setError(data.error || data.message)
		setLoading(false);
		
	}

	return (
		<Container>
			<Content>
				<Logo className="logo" src={logoSrc}/>
				 
				
					<Unform onSubmit={request}>
						<Title>Área do administrador</Title>
						<Divider> <p style={{opacity: 0}}>a</p></Divider>
						{error && <Error>{error}</Error>}
						<InputContainer>
							<Input 
								required
								name="cpf"
								type="number"
								label="CPF"
								placeholder="Insira o seu CPF"/>
						</InputContainer>

						<InputContainer>
							<Input 
								label="Senha"
								required
								className="inputLogin"
								name="password"
								type="password"
								placeholder={firstAccess ? 'Crie uma senha' : 'Insira sua senha'}/>
						</InputContainer>



						<div style={{display: 'flex', marginTop: 10}}>

							{loading ? (
								<Button style={{marginLeft: 'auto',}}>
									Carregando...
								</Button>
							) : (
								<Button style={{marginLeft: 'auto'}}>
									{!firstAccess ? 'Entrar' : 'Criar conta'}
								</Button>
							)}

						</div>
				 	</Unform>
			</Content>
		</Container>
	);
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actions, dispatch)


export default connect(null, mapDispatchToProps)(Login)