import React, { useEffect, useRef } from 'react'
import Select from 'react-select'
import { useField } from '@unform/core'
import styled from 'styled-components'


function SelectPicker({
  name,
  options,
  style,
  label,
  disabled,
  onChange = () => {},
  placeholder,
  initialValue,
  ...rest
}) {
  const selectRef = useRef(null)
  // eslint-disable-next-line
  const { fieldName, defaultValue, registerField } = useField(name)
  
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        const { value } = ref.state

        if (rest.isMulti) {
          if (!value) return []
          return value.map(({value}) => value)
        }
        
        if (!value) return ''

        return value.value
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  const changeObserver = data => {
    onChange(data)
  }

  return (
    <GenderPicker>
      <Disabler disabled={disabled}/>
      {label && <Label>{label}</Label>}
      <Select
        styles={style}
        defaultValue={initialValue}
        ref={selectRef}
        placeholder={placeholder}
        onChange={changeObserver}
        name='gender'
        options={options}
      />
    </GenderPicker>
  )
}

const GenderPicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  * {
    display: block;
  }
`
const Label = styled.div`
  color: white;
  font-family: 'Bree Serif', serif;
  margin: 5px;
`

const Disabler = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: ${({disabled}) => (disabled ? 20 : -20)};
`

export default SelectPicker