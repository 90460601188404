import styled from 'styled-components';

import { Form } from '@unform/web';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	background: #008040;
	background-color: var(--background-color);
	
`;

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin-left: 325px;
`

export const Unform = styled(Form)`
	padding: 20px 50px 0px 50px;
	width: calc(100% - 100px);
	display: flex;
	justify-content: center;
`
export const List = styled.table`
	width: 100%;
	padding: 30px 50px 20px 50px;
	background-color: var(--background-color);
`


export const FirstLine = styled.tr`
	background: rgba(255,255,255,0.8);
	padding: 10px;
`

export const FirstColumns = styled.th`
	padding: 10px;
	color: rgba(0,0,0,0.6);
`

export const Line = styled.tr`
	
`

export const Column = styled.th`
	padding: 10px;
	border-bottom: 1px solid rgba(255,255,255,0.8);
	color: var(--main-color);
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
`

export const ButtonOutlined = styled.button`
	display: flex;
	justify-content: center;
	padding: 10px;
	background: none;
	border-radius: 6px;
	border: 1px solid #fff;
	color: var(--main-color);
	font-family: 'Bree Serif', serif;
	font-size: 18px;
	margin-left: 10px;
`