import styled from 'styled-components';


export const Container = styled.button`
  padding: 10px ;
  opacity: 0.6;
  border-radius: 20px;
  background: var(--transparency-main-color);
  cursor: pointer;
  width: 60px;
  border: 0;
  

  svg {
    color: var(--main-color);
    width: 22px;
    height: 22px;
  }

  :hover {
    opacity: 1;
  }
`;
