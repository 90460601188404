import React, { useState, useEffect, useCallback } from 'react'

import { Container, Wrapper, Toolbar, SectionTitle, Right, TextButton, List } from './styles'

import Benefit from '../../Components/Benefit'
import SideMenu from '../../Components/SideMenu'

import api from '../../Services/api'
function Benefits() {
	const [ benefits, setBenefits ] = useState([])

	const getBenefits = useCallback(async () => {
		const { data, status } = await api.get('/benefits')

		if(status == 200) return setBenefits(data)
	})

	useEffect(() => {
		getBenefits()
	}, [])



	return (
		<Container>
			<SideMenu />

			<Wrapper>
				<Toolbar>
					<SectionTitle>Convênios</SectionTitle>

					<Right>
						<TextButton to="/benefits/new">
							Adicionar Novo
						</TextButton>
					</Right>		
				</Toolbar>

				<List>
					{benefits.map(benefit => (
						<Benefit key={benefit._id} info={benefit} />
					))}
				</List>
			</Wrapper>
		</Container>
	);
}

export default Benefits;