import React, {
  forwardRef,
  useCallback,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { Container } from "./styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import cep from "cep-promise";
import { useSWRConfig } from "swr";
import * as Yup from "yup";

import { Form } from "@unform/web";
import Input from "../../Input";
import InputMask from "../../InputMask";

import Select from "../../Select";
import api from "../../../Services/api";
import validatorSchema from "./formValidationSchema";

const listaPlanosSaude = [
  { value: "goldnacionalA", label: "Ouro Nacional - Enfermaria" },
  { value: "goldnacionalB", label: "Ouro Nacional - Apartamento" },
  { value: "c50pool", label: "Company 50 - Enfermaria" },
  { value: "c50star", label: "Company 50 - Apartamento" },
];

const listaTabelasPrecos = [
  { value: "tabela-8.36", label: "Tabela 8,36%" },
  { value: "tabela-15.16", label: "Tabela 15,16%" },
];

const listaDesconto = [
  { value: "sem-desconto", label: "Sem Desconto" },
  { value: "desconto-40", label: "Desconto de 40%" },
  { value: "desconto-45", label: "Desconto de 45%" },
];

function Template(props, ref) {
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [healthPlan, setHealthPlan] = useState(null);
  const [priceTable, setPriceTable] = useState(null);
  const [discount, setDiscount] = useState({
    value: "sem-desconto",
    label: "Sem Desconto",
  });
  const [hasOdonto, setHasOdonto] = useState(null);
  const [associationFee, setHasAssociationFee] = useState(null);
  const [associado, setAssociado] = useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    setHealthPlan(null);
    setHasOdonto(null);
    setAssociado(null);
    setLoading(false);
  }, []);

  const handleOpen = useCallback(async ({ associado }) => {
    setOpen(true);

    if (associado) {
      const { data, status } = await api.get(`/admin/user/${associado.id}`);

      if (status > 201) handleClose();

      setAssociado(data.user);

      formRef.current.setData({
        ...data.user,
      });

      if (!!data.user.planoSaude) {
        const planoSaude = listaPlanosSaude.find(
          (plano) => plano.value === data.user.planoSaude
        );
        setHealthPlan(planoSaude);
      }

      if (!!data.user.tabelaPlanoSaude) {
        const tabelaPlanoSaude = listaTabelasPrecos.find(
          (tabela) => tabela.value === data.user.tabelaPlanoSaude
        );
        setPriceTable(tabelaPlanoSaude);
      }

      if (!!data.user.descontoPlanoSaude) {
        const desconto = listaDesconto.find(
          (desconto) => desconto.value === data.user.descontoPlanoSaude
        );
        setDiscount(desconto);
      }

      setHasOdonto({
        value: data.user.hasOdonto || false,
        label: data.user.hasOdonto ? "Sim" : "Não",
      });

      setHasAssociationFee({
        value: data.user.shouldPayAssociationFee || false,
        label: data.user.shouldPayAssociationFee ? "Sim" : "Não",
      });

      console.log(data.user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
      handleClose,
    };
  });

  const handleSubmit = async (formData) => {
    setLoading(true);
    formRef.current.setErrors({});

    const associadoDTO = {
      ...associado,
      ...formData,
      cpf: formData.cpf.replace(/\D/g, ""),
      cep: formData.address.cep.replace(/\D/g, ""),
      telefone: formData.telefone.replace(/\D/g, ""),
      planoSaude: healthPlan ? healthPlan.value : null,
      hasOdonto: hasOdonto ? hasOdonto.value : false,
      shouldPayAssociationFee: associationFee ? associationFee.value : null,
      descontoPlanoSaude: discount ? discount.value : null,
      tabelaPlanoSaude: priceTable ? priceTable.value : null,
    };

    try {
      await validatorSchema.validate(associadoDTO, {
        abortEarly: false,
      });

      let statusCode = 500;
      if (associado) {
        const { data, status } = await api.put(
          `/admin/user/${associado.id}`,
          associadoDTO
        );
        statusCode = status;
      } else {
        const { data, status } = await api.post("/admin/user", associadoDTO);
        statusCode = status;
      }

      setLoading(false);

      if (statusCode < 300) {
        toast.success(
          associado
            ? "Associado atualizado com sucesso"
            : "Associado cadastrado com sucesso"
        );
        handleClose();
      }
    } catch (err) {
      setLoading(false);

      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        toast.error(
          "Verifique os campos indicados com erro e tente novamente."
        );
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        toast.error("Algo deu errado. Tente novamente.");
      }
    }

    //if(!data.error) return history.replace(`/admin/user/${data.user}`)
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container>
        <header>
          <h3>Associado</h3>
          <h1>{!associado ? "Cadastrar Associado" : associado.name}</h1>
        </header>

        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={associado || {}}
        >
          <h3>Dados Pessoais</h3>

          <Input
            className="input"
            name="name"
            required={true}
            placeholder="Nome completo"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <InputMask
            className="input"
            name="dtAssociacao"
            mask="99/99/9999"
            type="string"
            required={true}
            placeholder="Data de Associação"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <InputMask
            className="input"
            name="cpf"
            mask="999.999.999-99"
            required={true}
            placeholder="CPF"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="rg"
            required={true}
            placeholder="Identidade"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <InputMask
            className="input"
            name="birthdate"
            mask="99/99/9999"
            type="string"
            required={true}
            placeholder="Data de Nascimento"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="email"
            type="email"
            required={true}
            placeholder="Email"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="instituicaoEmpregadora"
            required={true}
            placeholder="Instituição empregadora"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="campusInstituicaoEmpregadora"
            required={true}
            placeholder="Campus"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <InputMask
            className="input"
            name="telefone"
            required={true}
            placeholder="Telefone"
            mask="(99) 9 9999-9999"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />
          <Input
            className="input"
            name="convenio"
            required={true}
            placeholder="Convênio"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <h3>Endereço</h3>

          <Input
            className="input"
            name="address.cep"
            required={true}
            placeholder="CEP"
            onBlur={(event) => {
              if (!event.target.value)
                return formRef.current.setFieldError(
                  "address.cep",
                  "CEP inválido"
                );

              cep(event.target.value.replace(/\D/g, ""), { providers: [ 'brasilapi', 'viacep']})
                .then((data) => {
                  console.log("data ceo", data);

                  const addressDTO = {
                    address: {
                      full: data?.street || "",
                      uf: data?.state || "",
                      neighborhood: data?.neighborhood || "",
                      city: data?.city || "",
                      cep: event.target.value
                    },
                  };
                  // this.setState({...this.state, ...addressDTO });

                  formRef.current.setData(addressDTO);
                })
                .catch((error) => {
                  console.error(error)
                  alert(
                    "Consulta automatica de CEP não disponível no momento. Preencha os campos manualmente."
                  );
                });
            }}
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="address.number"
            required={true}
            type="number"
            placeholder="Número"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="address.full"
            required={true}
            placeholder="Logradouro"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="address.neighborhood"
            required={true}
            placeholder="Bairro"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="address.city"
            required={true}
            placeholder="Cidade"
            
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="address.uf"
            required={true}
            placeholder="UF"
            
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="address.complemento"
            required={true}
            placeholder="Complemento"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <h3>Taxa de Associação</h3>

          <div style={{ width: "90%" }}>
            <Select
              name="shouldPayAssociationFee"
              placeholder="Deve pagar taxa de associação?"
              options={[
                { value: true, label: "Sim" },
                { value: false, label: "Não" },
              ]}
              onChange={setHasAssociationFee}
              value={associationFee}
            />
          </div>

          <h3>Plano de Saúde</h3>

          <div style={{ width: "90%" }}>
            <Select
              name="planoSaude"
              placeholder="Selecione um Plano de Saúde"
              options={listaPlanosSaude}
              onChange={setHealthPlan}
              isClearable={true}
              value={healthPlan}
            />
          </div>

          <div style={{ width: "90%", marginTop: "5px" }}>
            <Select
              name="tabelaPlanoSaude"
              placeholder="Selecione uma tabela de preços"
              options={listaTabelasPrecos}
              onChange={setPriceTable}
              isClearable={true}
              value={priceTable}
            />
          </div>

          <div style={{ width: "90%", marginTop: "5px" }}>
            <Select
              name="descontoPlanoSaude"
              placeholder="Selecione um desconto"
              options={listaDesconto}
              onChange={setDiscount}
              isClearable={true}
              value={discount}
            />
          </div>

          <Input
            className="input"
            name="monthsToIncrease"
            type="number"
            required
            placeholder="Número de parcelas adicionais"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <InputMask
            className="input"
            name="dtAssociacaoPlanoSaude"
            mask="99/99/9999"
            type="string"
            required={true}
            placeholder="Data de Associação ao Plano de Saúde"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <h3>Odonto</h3>

          <div style={{ width: "90%" }}>
            <Select
              name="hasOdonto"
              placeholder="Associado Possui Odonto?"
              options={[
                { value: true, label: "Sim" },
                { value: false, label: "Não" },
              ]}
              onChange={setHasOdonto}
              value={hasOdonto}
            />
          </div>

          <InputMask
            className="input"
            name="dtAssociacaoOdonto"
            mask="99/99/9999"
            type="string"
            required={true}
            placeholder="Data de Associação ao Plano de Saúde"
            style={{ width: "90%", margin: "5px 0px", flexGrow: 0 }}
          />

          <Input
            className="input"
            name="valueOdonto"
            type="number"
            required
            placeholder="Valor do Odonto"
            style={{
              width: "90%",
              margin: "5px 0px",
              flexGrow: 0,
              paddingBottom: 50,
            }}
          />
        </Form>
      </Container>

      <DialogActions>
        <Button style={{ marginRight: "auto" }} onClick={handleClose}>
          <strong>Fechar</strong>
        </Button>
        <Button disabled={loading} onClick={() => formRef.current.submitForm()}>
          <strong>{loading ? "Carregando..." : "Enviar"}</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default forwardRef(Template);
