import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
`

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin-left: 325px;
`

export const Toolbar = styled.div`
	display: flex;
	padding: 50px 50px 0px 50px;
`

export const SectionTitle = styled.span`
	color: var(--main-color);
	font-size: 25px;
	margin-bottom: 15px;
`
export const Unform = styled(Form)`
	width: calc(100% - 100px);
	padding: 0px 50px 0px 50px;
	display: flex !important;
	flex-wrap: wrap;
`

export const InputContainer = styled.div`
	width: calc(30% - 20px);
	margin-right: 20px;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	flex-grow:1;
`;


export const SpanInput = styled.span`
	font-family: 'Bree Serif', serif;
	font-weight: 300;
	opacity: 0.7;
	color: var(--main-color);
`;

export const ButtonOutlined = styled.button`
	display: flex;
	justify-content: center;
	padding: 10px;
	background: none;
	border-radius: 6px;
	border: 1px solid #fff;
	color: var(--main-color);
	font-family: 'Bree Serif', serif;
	font-size: 18px;
	margin: 30px 70px 20px 0;
`