import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #fafafa;
`;

export const Logo = styled.img`
	width: 230px;
	height: 84px;
	display: flex;


	@media (max-width: 325px) {
		width: 170px;
		height: 64px;		
	}
`;


export const Title = styled.span`
	font-family: 'Bree Serif', serif;
	font-weight: 300;
	opacity: 1;
	color: #44a7b3;
	font-size: 22px;
	text-align: center;
	
`;

export const List = styled.table`
	width: 90%;
	
`

export const Span = styled.span`
	font-family: 'Bree Serif', serif;
	font-weight: 300;
	opacity: 0.6;
	color: #000;
	font-size: 22px;
`;

export const TitleTable = styled.span`
	margin-top: 30px;
	color: #44a7b3;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1px;
`

export const FirstLine = styled.tr`
	background: #44a7b3;
	padding: 10px;
`

export const FirstColumns = styled.th`
	padding: 10px;
	color: var(--main-color);
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	letter-spacing: 1px;
`

export const Line = styled.tr`
	border: 1px solid #44a7b3;
`

export const Column = styled.th`
	padding: 10px;
	color: #000;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	border-bottom: 1px solid rgba(48,187,219, 0.6);
	
`