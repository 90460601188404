import React, { useState, useEffect, useRef } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { Link } from 'react-router-dom';

import UserDialog from '../Dialogs/Associado'
import DialogNovoPagamento from '../Dialogs/DialogNovoPagamento'

import {
  handleCreatePayments,
  handleProcessReturn,
  handleCreateOdonto,
  handleProcessRemessa
} from '../../Services/payments'

import { Container, ActionSpan, SearchInput} from './styles'

import { useSWRConfig } from 'swr'

import { useLocation } from 'react-router-dom'

import {
	faStethoscope,
	faTooth,
	faPaperPlane,
	faDownload,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function TopMenu({ history }) {
  const dialogUserRef = useRef(null)
  const dialogPaymentRef = useRef(null)
  const [options, setOptions] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  const { mutate } = useSWRConfig()

  const optionsByPath = {
    '/pagamentos': [
      {
        description: 'Novo Pagamento',
        icon: faPlus,
        action: () => {
          setAnchorEl(null);
          dialogPaymentRef.current.handleOpen({});
        }
      }, 
      {
        description: 'Gerar Débito Plano',
        icon: faStethoscope,
        action: () => {
          setAnchorEl(null);
          handleCreatePayments({ mutate })
        }
      }, 
      {
        description: 'Gerar Débito Odonto',
        icon: faTooth,
        action: () => {
          setAnchorEl(null);
          handleCreateOdonto({ mutate })
        }
      }, 
      {
        description: 'Enviar Remessa',
        icon: faPaperPlane,
        action: () => {
          setAnchorEl(null);
          handleProcessRemessa({ mutate })
        }
      }, 
      {
        description: 'Sincronizar Retorno',
        icon: faDownload,
        action: () => {
          setAnchorEl(null);
          handleProcessReturn({ mutate })
        }
      }, 

    ],
    '/titulares': [
      {
        description: 'Adicionar Novo Titular',
        icon: faPlus,
        action: () => {
          setAnchorEl(null);
          dialogUserRef.current.handleOpen({});
        }
      },
    ]
  }

  useEffect(() => {
    console.log('location', location);
    const pathOptions = optionsByPath[location.pathname];

    if(pathOptions)
      setOptions(pathOptions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Container display={location.pathname !== '/'} >

      <UserDialog ref={dialogUserRef} />
      <DialogNovoPagamento ref={dialogPaymentRef} />

      <h2>IASP</h2>

      <section id='page-groups'>

        <Link to='/dashboard'>
          <Button>Painel</Button>
        </Link>

        <Link to='/titulares'>
          <Button>Associados</Button>
        </Link>

        <Link to='/pagamentos'>
          <Button>Pagamentos</Button>
        </Link>

      </section>

      <section id='page-options'>

        <Tooltip title='' arrow open={!anchorEl}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Ações
          </Button>
        </Tooltip>

      {(options && options.length > 0) && (
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map(option => (
          <MenuItem onClick={option.action} key={option.description}>
          <FontAwesomeIcon icon={ option.icon } size={"sm"} />
          <ActionSpan>{option.description}</ActionSpan>
        </MenuItem>
        ))}
        </Menu>
      )}
      </section>


    </Container>
  );
}

export default TopMenu;