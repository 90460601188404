import React from 'react'

import { Container, Page } from './styles'

// eslint-disable-next-line react/prop-types
function PageGroup({Icon, color, title, route, to}) {
	return (
		<Container to={to}>
			<Icon size={32} color={color}/>
			<Page>{title}</Page>
		</Container>
	)
}

export default PageGroup