const Modalities = [
	{
		ageMin: 0,
		ageMax:18,
		c50pool:143.7,
		c50star:186.52,
		goldnacionalA:207.92,
		goldnacionalB:258.8,
	},
	{
		ageMin: 19,
		ageMax: 23,
		c50pool: 158.06,
		c50star: 205.51,
		goldnacionalA: 239.16,
		goldnacionalB: 297.67,
	},
	{
		ageMin: 24,
		ageMax: 28,
		c50pool: 186.52,
		c50star: 242.5,
		goldnacionalA: 275,
		goldnacionalB: 342.29,
	},
	{
		ageMin: 29,
		ageMax: 33,
		c50pool: 220.1,
		c50star: 286.14,
		goldnacionalA: 302.48,
		goldnacionalB: 376.48,
	},
	{
		ageMin: 34,
		ageMax: 38,
		c50pool: 254.23,
		c50star: 330.5,
		goldnacionalA: 332.63,
		goldnacionalB: 414.02,
	},
	{
		ageMin: 39,
		ageMax: 43,
		c50pool: 307.62,
		c50star: 399.9,
		goldnacionalA: 365.87,
		goldnacionalB: 455.39,
	},
	{
		ageMin: 44,
		ageMax: 48,
		c50pool: 362.99,
		c50star: 471.88,
		goldnacionalA: 457.36,
		goldnacionalB: 569.27,
	},
	{
		ageMin: 49,
		ageMax: 53,
		c50pool: 468.24,
		c50star: 608.74,
		goldnacionalA: 594.51,
		goldnacionalB: 739.96,
	},
	{
		ageMin: 54,
		ageMax: 58,
		c50pool: 571.25,
		c50star: 742.67,
		goldnacionalA: 772.84,
		goldnacionalB: 961.92,
	},
	{
		ageMin: 59,
		ageMax: 150,
		c50pool: 856.90,
		c50star: 1113.99,
		goldnacionalA: 1004.68,
		goldnacionalB: 1250.50,
	},

]

export default Modalities