/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { 
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Container from '../../Components/PageContainer';
import Topbar from '../../Components/TopBar';
import EditUser from '../../Components/EditUser';
import EditDependents from '../../Components/EditDependents';

import EditPayments from '../../Components/EditPayments';

import api from '../../Services/api';

export default function User({history}) {
	const [ user, setUser ] = useState({})
	const [ dependents, setDependents] = useState(false)
	const [ payments, setPayments] = useState(false)

	const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
	
	const { id } = useParams();

	const getUserData = async () => {
		const { data } = await api.get(`/admin/user/${id}`)

		if(!data.error) {
			setUser(data.user)
			if(data.user.dependents.length > 0) 
				setDependents(data.user.dependents)
			
			if(data.payments.length > 0) 
				setPayments(data.payments)
		}
	}

	useEffect(() => {
		getUserData()
	}, [])

  return (
	<Container>

		<Topbar 
			title="Detalhes do Titular" 
			showBackButton
			history={history} /> 

		<div style={{ padding: "40px 40px 0px 40px"}}>

			<Accordion expanded={expanded === 'panel1'} className="accordion" onChange={handleChange('panel1')}>
				<AccordionSummary
					expandIcon={<FontAwesomeIcon icon={faChevronDown} color="var(--main-color)" />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					color="primary">

					Informações Pessoais
				
				</AccordionSummary>
				<AccordionDetails>
					<EditUser user={user} id={id} history={history} />
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel2'} className="accordion" onChange={handleChange('panel2')}>
					<AccordionSummary
						expandIcon={<FontAwesomeIcon icon={faChevronDown} color="var(--main-color)" />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						color="primary">

						Dependentes
					
					</AccordionSummary>
				<AccordionDetails>
					<EditDependents dependents={dependents} id={id}/>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel3'} className="accordion" onChange={handleChange('panel3')}>
					<AccordionSummary
						expandIcon={<FontAwesomeIcon icon={faChevronDown} color="var(--main-color)" />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						color="primary">

						Pagamentos
					
					</AccordionSummary>
				<AccordionDetails>
					<EditPayments payments={payments} id={id}/>
				</AccordionDetails>
			</Accordion>

			<Accordion expanded={expanded === 'panel4'} className="accordion" onChange={handleChange('panel4')}>
					<AccordionSummary
						expandIcon={<FontAwesomeIcon icon={faChevronDown} color="var(--main-color)" />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						color="primary">

						Serviços
					
					</AccordionSummary>
				<AccordionDetails>
					<EditPayments payments={payments} id={id}/>
				</AccordionDetails>
			</Accordion>


		</div>
		
	</Container>
  );
}
