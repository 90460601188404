import React, { useCallback, useEffect, useState } from 'react';

import { 
	Card,
	CardContent,
	Typography,
	Divider,
	Stack,
	Chip
} from '@mui/material';

import { Container } from './styles';
import GraphQL from '../../Services/graphql';
import { TODOS, VENCIDOS, PAGOS } from './queries'
import { subMonths } from 'date-fns';

export function formatMoney(number = 0, prefix = true, absolute = true) {
	number = Number(number);
	if(typeof number === 'number') {
			number = absolute ? Math.abs(number) : number;
			const convertedNumber = number.toLocaleString('pt-BR', { currency: 'BRL', maximumFractionDigits: 2, minimumFractionDigits: 2 });
			return prefix ? `R$ ${convertedNumber}` : convertedNumber;
	}
	else 
			return "-";
}


function Dashboard() {
	const [data, setData] = useState({})
	const [dates, setDates] = useState([])
	const [dateSelected, setDateSelected] = useState({
		mesReferencia: new Date().getMonth() + 1,
		anoReferencia: new Date().getFullYear()
	})

	async function getAll() {
		const todos = await GraphQL.post('', { query: TODOS(dateSelected) })
		const vencidos = await GraphQL.post('', { query: VENCIDOS(dateSelected) })
		const pagos = await GraphQL.post('', { query: PAGOS(dateSelected) })

		const newData = {
			todos: {
				value: todos.data.data.Payment_aggregate.aggregate.sum.value,
				count: todos.data.data.Payment_aggregate.aggregate.count,
			},
			pago: {
				value: pagos.data.data.Payment_aggregate.aggregate.sum.value,
				count: pagos.data.data.Payment_aggregate.aggregate.count,
			},
			vencidos: {
				value: vencidos.data.data.Payment_aggregate.aggregate.sum.value,
				count: vencidos.data.data.Payment_aggregate.aggregate.count,
			},
		}

		console.log('newData', newData)
		setData(newData)
	}

	function createChipDates() {
		const date = new Date()
		const dates = []

		for (let index = 0; index <= 12; index++) {
			const dateOnMonth = subMonths(date, index);

			dates.push({
				mesReferencia: dateOnMonth.getMonth() + 1,
				anoReferencia: dateOnMonth.getFullYear()
			})
		}

		setDates(dates);

	}

	useEffect(() => {
		createChipDates()

	}, [])

	useEffect(() => {
		getAll()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateSelected])

	
	return (
		<Container>
		
		<div style={{display: 'flex', marginBottom: '20px'}}>
			{dates.map(date => (
				<Chip
					onClick={() => {setDateSelected({ mesReferencia: date.mesReferencia, anoReferencia: date.anoReferencia })}}
					label={`${date.mesReferencia}/${date.anoReferencia}`} 
					color={(date.mesReferencia === dateSelected.mesReferencia && date.anoReferencia === dateSelected.anoReferencia) ? 'primary' : 'waiting'}
					key={`${date.mesReferencia}/${date.anoReferencia}`}
					sx={{marginRight: '10px', cursor: 'pointer'}}/>
			))}
			
		</div>
			

		{data.todos ? (
			<div style={{display: 'flex', marginBottom: '20px', flexWrap: 'wrap',}}>
				<Card sx={{ minWidth: 275, maxWidth: 400, margin: '5px' }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
							Estimativa de Arrecadamento
						</Typography>
						<Typography variant="h5" component="div">
						</Typography>
						<Typography sx={{ mb: 1, fontSize: 40, fontWeight: 'bold', color: '#0F3460'}} >
							{formatMoney(data?.todos?.value || 0)}
						</Typography>
					</CardContent>
				</Card>

				<Card sx={{ minWidth: 275, margin: '5px' }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
							Arrecadado até o momento
						</Typography>
						<Typography variant="h5" component="div">
						</Typography>
						<Typography sx={{ mb: 1, fontSize: 40, fontWeight: 'bold', color: '#367E18'}} >
							{formatMoney(data?.pago?.value || 0)}
						</Typography>
					</CardContent>
				</Card>

				<Card sx={{ minWidth: 275, margin: '5px' }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
							A Receber
						</Typography>
						<Typography variant="h5" component="div">
						</Typography>
						<Typography sx={{ mb: 1, fontSize: 40, fontWeight: 'bold', color: '#CC3636'}} >
							{formatMoney(data?.vencidos?.value || 0)}
						</Typography>
					</CardContent>
				</Card>

				<Card sx={{ minWidth: 150, margin: '5px' }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
							Boletos Emitidos
						</Typography>
						<Typography variant="h5" component="div">
						</Typography>
						<Typography sx={{ mb: 1, fontSize: 40, fontWeight: 'bold', color: '#0F3460' }} >
							{data.todos.count || 0}
						</Typography>
					</CardContent>
				</Card>

				<Card sx={{ minWidth: 150, margin: '5px',}}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
							Boletos Aguardando Pagamento
						</Typography>
						<Typography variant="h5" component="div">
						</Typography>
						<Typography sx={{ mb: 1, fontSize: 40, fontWeight: 'bold', color: '#FF4A4A' }} >
							{data.vencidos.count || 0}
						</Typography>
					</CardContent>
				</Card>

			</div>
		) : <></>}
		</Container>
	);
}

export default Dashboard;