import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react'

import MaterialTheme from './materialTheme';
import { ThemeProvider } from '@mui/material/styles';

import Routes from './routes'
import { persistor, store }  from './store';
import './index.css';

import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
		<ThemeProvider theme={MaterialTheme}>
				<ToastContainer />
				<Provider store={store}>
					<PersistGate persistor={persistor}>
						<Routes />
					</PersistGate>
				</Provider>
		</ThemeProvider>,
  document.getElementById('root')
);


