const INITIAL_STATE = {
  "user": null,
  token: null,
}

// Reducer
export default function login(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return { ...state, ...action.payload.user, };
            break;
        case 'LOGOUT':
            return INITIAL_STATE
        default:
            return state;
    }
}