import React, { Component } from 'react';

import { Container, Unform, List, FirstLine, FirstColumns, Line, Column, ButtonOutlined, Wrapper } from './styles';

import SideMenu from '../../Components/SideMenu';

import Input from '../../Components/Input';

import Simulate from '../../Services/simulator'

export default class Simulator extends Component {


	state = {
		items: [],
		price: 0
	}

	getValue = (info) => {
		const value = Simulate(info)

		info.value = value

		let { items, price } = this.state 

		items.push(info)
		price = parseFloat(price) + parseFloat(value)

		this.setState({
			items, 
			price
		})
	}

 	render() {
		return (
			<Container>
				<SideMenu />

				<Wrapper>

					<Unform onSubmit={this.getValue}>
						<Input 
							className="input"
							name="planDescription"
							required
							placeholder="Descrição do Plano"
							/>

						
						<Input 
							style={{marginLeft: 20}}
							className="input"
							name="plan"
							required
							placeholder="Plano (A OU B)"
							/>

						<Input 
							style={{marginLeft: 20}}
							className="input"
							name="age"
							required
							placeholder="Idade"
							/>

						<ButtonOutlined type="submit">
							Adicionar
						</ButtonOutlined>


					</Unform>

					<List>
						<tbody>
							<FirstLine>
								<FirstColumns>Descrição do Plano</FirstColumns>
								<FirstColumns>Plano</FirstColumns>
								<FirstColumns>Idade</FirstColumns>
								<FirstColumns>Valor</FirstColumns>
								<FirstColumns></FirstColumns>
							</FirstLine>

						{this.state.items.map((item, index) => (
							<Line key={index}>
								
								<Column>{item.planDescription}</Column>
								<Column>{item.plan}</Column>
								<Column>{item.age}</Column>
								<Column>{item.value}</Column>
							</Line>
						))}

							<Line key={100}>
								
								<Column></Column>
								<Column></Column>
								<Column></Column>
								<Column></Column>
								<Column>Total: R$ {this.state.price}</Column>
							</Line>
						</tbody>
					</List>

				</Wrapper>



				
			</Container>
		)
  	}
}
