import styled from 'styled-components';
import { Link } from 'react-router-dom'

const getColor = (isRedEnabled) => {
  return {
    transparency: !isRedEnabled ? 'var(--transparency-main-color)' : 'var(--transparency-deny-color)',
    main:  !isRedEnabled ?  'var(--main-color)': 'var(--deny-color)'
  }
}

export const Container = styled(Link)`
  width: ${props => props.vertical ? '170px' : '125px'};
  height: ${props => props.vertical ? '50px' : '125px'};
  border-radius: 10px;
  border: 0;
  background: ${props => getColor(props.deny).transparency};
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-direction: ${props => props.vertical ? 'row' : 'column'};
  align-items: center;
  justify-content: ${props => props.vertical ? 'none' : 'center'};;
  cursor: pointer;
  transition: all 0.2s;
  margin: ${props => props.vertical ? '7px' : '10px'};
  padding: ${props => props.vertical ? '0px 5px 0 15px' : '0px'};
  text-decoration: none;

  box-shadow: 0 2px 4px 0 ${props => getColor(props.deny).main}, 0 3px 10px 0 ${props => getColor(props.deny).transparency};

  svg {
    color: ${props => getColor(props.deny).main};
  }

  span {
    font-weight: bold;
    color: ${props => getColor(props.deny).main};
    font-size: 15px;
    margin-top: ${props => props.vertical ? '0' : '15px'};
    margin-left: ${props => props.vertical ? '10px' : '0px'};
  }

  :hover {
    box-shadow: 0 4px 8px 0 ${props => getColor(props.deny).main}, 0 6px 20px 0 ${props => getColor(props.deny).main};
    transform: scale(1.02);
  }

 
`;
