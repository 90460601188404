import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Container, Content, Card, InputContainer, SpanInput, Button, Form, Wrapper } from './styles';

import SideMenu from '../../Components/SideMenu';

import Input from '../../Components/Input';
import InputFile from '../../Components/InputFile';

import api from '../../Services/api'

import * as actions from '../../store/actions/report'

class Report extends Component {

	state = {
		loadFile: false,
		edit: false
	}

	send = async (form) => {
		let info;

		if(this.state.loadFile !== false) {
			const formData = new FormData();
			formData.append('file', form.file);
			formData.append('retroativo', form.retroativo)
			formData.append('subscription', form.subscription)
			formData.append('card', form.card)
			formData.append('part', form.part)
			formData.append('period', form.period)
			formData.append('discount', form.discount)
			formData.append('rDescription', form.retroativoDescription)

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}

			const {data} = await api.post('/admin/report/file', formData, config)
			info = data
		} else {
			const {data} = await api.post('/admin/report', form)
			info = data
		}
		this.props.report(info)

		this.props.history.push('/report/view')
	}
	
	render() {

		return (
			<Container>
				<SideMenu />
	
				<Wrapper>
					{!this.state.edit && (
						<Content>
							<Card onClick={() => this.setState({loadFile: true, edit: true })}>
								Gerar por arquivo .csv
							</Card>
		
							<Card onClick={() => this.setState({edit: true })}>
								Gerar por usuários cadastrados
							</Card>
						</Content>
					)}

					{this.state.edit && (
						<Content>
							<Form onSubmit={this.send}>

								{this.state.loadFile && (
									<InputContainer>
										<SpanInput>Arquivo</SpanInput>
										<InputFile 
											required
											name="file"
											type="file"/>
									</InputContainer>
								)}

								<InputContainer>
									<SpanInput>Período</SpanInput>
									<Input 
										name="period"
										type="text"
										placeholder="Insira a data"/>
								</InputContainer>

								

								<InputContainer>
									<SpanInput>Retroativo</SpanInput>
									<Input 
										
										name="retroativo"
										type="text"
										placeholder="Insira o valor"/>
								</InputContainer>

								<InputContainer>
									<SpanInput>Descrição do retroativo</SpanInput>
									<Input 
										name="retroativoDescription"
										type="text"
										placeholder="Ex: Abril/2020"/>
								</InputContainer>

								<InputContainer>
									<SpanInput>Taxa de inscrição</SpanInput>
									<Input 
										name="subscription"
										type="text"
										placeholder="Insira o valor"/>
								</InputContainer>

								<InputContainer>
									<SpanInput>Taxa de cartão</SpanInput>
									<Input 
										name="card"
										type="text"
										placeholder="Insira o valor"/>
								</InputContainer>

								<InputContainer>
									<SpanInput>Coparticipação</SpanInput>
									<Input 
										name="part"
										type="text"
										placeholder="Insira o valor"/>
								</InputContainer>


								<InputContainer>
									<SpanInput>Débitos</SpanInput>
									<Input 
										name="discount"
										type="text"
										placeholder="Insira o valor"/>
								</InputContainer>

								<Button>Enviar</Button>
								
							</Form>

						</Content>
					)}
				</Wrapper>
			</Container>
		)
	}
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(actions, dispatch)


export default connect(null, mapDispatchToProps)(Report)
