import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles'

export default function Input({ name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
      },
      setValue(value) {
      }
    })
  }, [fieldName, registerField]);
  return (
    <>
      <Container
        type="file"
        ref={inputRef}
        {...rest}
      />
    </>
  );
};
