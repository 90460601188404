import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, HashRouter } from 'react-router-dom';
import styled from 'styled-components';

import Home from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Titulares from './Pages/Titulares';
import User from './Pages/Titular';
import CreateUser from './Pages/CreateUser';
import CreatePayments from './Pages/Payments';
import Simulator from './Pages/Simulator';
import Report from './Pages/Report';
import ViewReport from './Pages/ViewReport';
import Benefits from './Pages/Benefits';
import NewBenefit from './Pages/NewBenefit';
import Benefit from './Pages/Benefit';
import Notification from './Pages/Notification';

import Menu from './Components/NewMenu'
import TopBar from './Components/TopMenu'

const Container = styled.div`
  display: flex;
	flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  overflow: hidden;
`;

const Routes = ({ user, token }) => (
    <HashRouter>
			{ !user ? (
				<Switch>
					<Route path="/" component={Home}/>
				</Switch>
			) : (
				<Container>
					{/* <Menu vertical={true}/> */}
					{user && <TopBar /> }
					<Switch>
						<Route exact path="/" component={Home}/>
						<Route exact path="/dashboard" component={Dashboard}/>
						<Route path="/titulares" component={Titulares}/>
						<Route path="/pagamentos" component={CreatePayments}/>
						<Route exact path="/report" component={Report}/>
						<Route path="/report/view" component={ViewReport}/>
						<Route path="/simulator" component={Simulator}/>
						<Route path="/benefit/:id" component={Benefit}/>
						<Route exact path="/benefits" component={Benefits}/>
						<Route path="/benefits/new" component={NewBenefit}/>
						<Route path="/admin/user/:id" component={User}/>
						<Route path="/admin/create/user" component={CreateUser}/>
						<Route path="/notification" component={Notification}/>
					</Switch>
				</Container>
			)}
	
    </HashRouter>
);

const mapStateToProps = state => ({
	user: state.login.user,
	token: state.login.token,
})


export default connect(mapStateToProps)(Routes)