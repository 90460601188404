
import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import InputMask from 'react-input-mask';

import TextField from '@mui/material/TextField'

const styles = {
  flexGrow: 1,
  margin: "10px 5px"
}

const CustomInputMask = (props) => {
  return <InputMask {...props} ref={props.inputRef} />
}

export default function Input({ name, mask, ref, style, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  const [masked, setMasked] = useState(defaultValue);


  useEffect(() => {
    registerField({
      name: fieldName,
      ref:  inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        setMasked(value)
      },
      clearValue: ref => {
        ref.setValue('');
      },
    })
  }, [fieldName, registerField])

  
  return (
    <InputMask
      error={!!error}
      defaultValue={defaultValue}
      mask={mask}
      value={masked}
      onChange={(event) => {setMasked(event.target.value)}}
      style={styles}>

        {() => <TextField inputRef={inputRef} InputLabelProps={{ shrink: true }}   {...rest} style={{...styles, ...style}} error={!!error} helperText={!!error ? error : ""}/>}
    </InputMask>
      
  )
}


