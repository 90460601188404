import React from 'react';

import styled from 'styled-components';

export const Container = styled.button`
	font-family: 'Montserrat', sans-serif;
	background: none;
	font-weight: 400;
	color: rgba(255,0,0,1);
	cursor: pointer;
	font-size: 18px;
	margin-top: auto;
	margin-bottom: 20px;
	align-self: center;
`;


function ExitButton() {
  return <Container>SAIR DA CONTA</Container>;
}

export default ExitButton;