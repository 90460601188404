import React, { forwardRef, useEffect, useCallback, useState, useImperativeHandle, useRef } from 'react';
import { Container } from './styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { Form } from '@unform/web';
import Input from '../../Input';
import InputMask from '../../InputMask';

import api from '../../../Services/api';
import { toast} from 'react-toastify';

function AdicionarDependente(props, ref) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [healthPlan, setHealthPlan] = useState(null);
  const [hasOdonto, setHasOdonto] = useState(null);
  const [associado, setAssociado] = useState(null);

  const handleOpen = useCallback(({user}) => {
    setOpen(true)

    if(user) {
      setAssociado(user)
    } 
    
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setHealthPlan(null)
    setHasOdonto(null)
    setAssociado(null)

  }, [])

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
      handleClose
    }
  })


  const handleSubmit = async (formData) => {
    setLoading(true)

    try {
      const response = await api.post('/admin/dependent', {
        ...formData,
        father: associado.id
      })

      setLoading(false)
      toast.success('Dependente cadastrado com sucesso!')
      handleClose()

    } catch(err) {
      toast.error("Erro ao adicionar dependente.")
    }

		//if(!data.error) return history.replace(`/admin/user/${data.user}`)
  }

  return (
    <Dialog open={open} onClose={handleClose}>

      <Container>
        <header>
          <h3>Novo Dependente de </h3>
          <h1>{associado?.name || "Carregando..."}</h1>
        </header>

        <Form onSubmit={handleSubmit} ref={formRef}>

          <h3>Dados Pessoais</h3>

          <Input 
            className="input"
            name="name"
            required={true}
            placeholder="Nome completo"
            style={{width: '90%', margin: "5px 0px", flexGrow: 0}}/>

          <Input 
            className="input"
            name="cpf"
            required={true}
            placeholder="CPF"
            style={{width: '90%', margin: "5px 0px", flexGrow: 0}}/>

          <Input 
            className="input"
            name="birthdate"
            required={true}
            placeholder="Data de Nascimento"
            style={{width: '90%', margin: "5px 0px", flexGrow: 0}}/>

          <h3>Produtos</h3>

          <InputMask
            className="input"
            name="dtHealthPlanAdhesion"
            mask="99/99/9999"
            type="string"
            required={true}
            placeholder="Data de Associação ao Plano de Saúde"
            style={{ width: '90%', margin: "5px 0px", flexGrow: 0 }} />

          <InputMask
            className="input"
            name="dtDentalPlanAdhesion"
            mask="99/99/9999"
            type="string"
            required={true}
            placeholder="Data de Associação ao Odonto"
            style={{ width: '90%', margin: "5px 0px", flexGrow: 0 }} />

        </Form>
      </Container>

     <DialogActions>
        <Button style={{marginRight: 'auto'}}onClick={handleClose}><strong>Fechar</strong></Button>
        <Button disabled={loading} onClick={() => formRef.current.submitForm()}><strong>{loading ? 'Carregando...' : 'Enviar'}</strong></Button>
     </DialogActions>

    </Dialog>
  );
}

export default forwardRef(AdicionarDependente);