import axios from 'axios';
import useSWR from "swr";


import store from '../store/index'
import { toast } from 'react-toastify';

const api = axios.create({
    /* baseURL: 'https://api.portaliasp.org', */
    baseURL: 'https://iasp-backend-l4qselo2vq-ue.a.run.app',
})

api.interceptors.request.use(async function (config) {
    const state = store.getState()

	if (state.login.token) {
		config.headers.Authorization = 'Bearer '+state.login.token;
	}
   
    return config;
})

api.interceptors.response.use(async function (response) {
    return response
}, (error, response) => {

    if(error.response.status === 401) {
        store.dispatch(
            {type: 'LOGOUT'}
        )
        return error.response
    }

    toast.error(
        error?.response?.data?.error?.message ||
        error?.response?.data?.error ||
        'Algo de errado não está certo. É hora de gritar pelo Francisco.'
    )

    return error.response
})

export const useFetch = (url) => {
    return useSWR(url, async (url) => {
        const response = await api.get(url)
        return response.data
    })
}


export default api;