import React, { forwardRef, useCallback, useState, useImperativeHandle } from 'react';
import { Container, Beneficiario, ButtonList } from './styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import ListItemButton from '../../ListItemButton';
import {
  faInfo,
  faSearch,
  faPlus,
  faTrash,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

import api from '../../../Services/api';

import { toast } from 'react-toastify';

const capitalize = (string) => {
  const stringLowercase = string.toLowerCase()
  return stringLowercase.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
}


function Dependentes(props, ref) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [associado, setAssociado] = useState(false)


  const handleOpen = useCallback(({ user }) => {
    console.log(user)

    if (user)
      setAssociado(user)
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
      handleClose
    }
  })

  const handleDelete = async ({ id, father }) => {

    try {
      toast.info("Excluindo dependente.")


      await api.delete(`/admin/dependent`, {
        data: {
          id,
          father
        }

      })

      handleClose()

    } catch (err) {
      toast.error("Erro ao deletar dependente.")
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>

      <Container>
        <header>
          <h3>Dependentes de</h3>
          <h1>{associado.name}</h1>
        </header>

        <main>

          {
            associado && associado.dependents.map(dependente => (
              <Beneficiario key={dependente._id}>

                <div>
                  <h3>{capitalize(dependente.name)}</h3>
                  <h2>{dependente.birthdate}</h2>
                </div>

                <ButtonList isVisible={"aa"}>

                  <ListItemButton
                    icon={faTrash}
                    title="Excluir"
                    onClick={() => handleDelete({ id: dependente.id, father: dependente.father })}
                  />

                </ButtonList>


              </Beneficiario>
            ))
          }

        </main>
      </Container>

      <DialogActions>
        <Button style={{ marginRight: 'auto' }} onClick={handleClose}><strong>Fechar</strong></Button>
      </DialogActions>

    </Dialog>
  );
}

export default forwardRef(Dependentes);