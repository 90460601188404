import React, { forwardRef, useCallback, useEffect, useState, useImperativeHandle, useRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useSWRConfig } from 'swr'
import Select from 'react-select'

import api from '../../Services/api';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function DialogNovoPagamento(props, ref) {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [type, setType] = useState(null)
  const [value, setValue] = useState('')

  const { mutate } = useSWRConfig()

  const handleClose = useCallback(() => {
    setOpen(false)
    setType(null)
    setType(null)
    setUser(null)
  }, [])

  const handleOpen = useCallback(({ associado }) => {
    setOpen(true)
    getUserList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUserList = async () => {

    const { data } = await api.get(`/admin/users`)

    const userList = data.map(user => {
      return {
        value: user.id,
        label: user.name.toUpperCase()
      }
    })

    setUsers(userList)
  }

  const handleCreatePayment = async () => {
    const newPaymentDTO = {
      father: user.value,
      value: Number(value),
      type: type.value,
    }

    const { data } = await api.post(`/admin/payment`, newPaymentDTO)

    mutate('/admin/payment/all?limit=2000')
    setValue('')
    setType(null)
    setUser(null)
    handleClose()
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
      handleClose
    }
  })

  

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Novo Pagamento</DialogTitle>
      <DialogContent style={{ width: 400, }}>
        <Select
          options={users}
          isSearchable={true}
          placeholder="Selecione um beneficiário"
          value={user}
          onChange={setUser}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
        <div style={{ marginTop: 10 }}></div>

        <Select
          options={[
            { value: 'mensalidadeOdonto', label: 'Mensalidade - Odonto' },
            { value: 'healthPlanMontlhyPayment', label: 'Mensalidade - Plano Saúde' },
            { value: 'healthPlanCoparticipacao', label: 'Coparticipação - Plano Saúde' },
            { value: 'taxaAdesao', label: 'Taxa de Adesão' },
            { value: 'taxaCarteirinha', label: 'Taxa de Carteirinha' },
            { value: 'parcelamentoMensalidadeAtrasada', label: "Parcelamento de Mensalidade"}
          ]}
          placeholder="Selecione um tipo de cobrança"
          value={type}
          onChange={setType}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />

        <TextField
          id="standard-basic"
          label="Valor (use ponto . ao invés de virgula , Ex: 149.90)"
          type="number"
          variant="standard"
          style={{ width: "100%", marginTop: 10 }}
          value={value}
          onChange={(event) => { setValue(event.target.value) }} />

      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
        <Button variant="contained" onClick={handleCreatePayment}>Cadastrar Pagamento</Button>
      </DialogActions>
    </Dialog>
  );
}

export default forwardRef(DialogNovoPagamento)