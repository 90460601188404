const INITIAL_STATE = {
	"c50pool": {
	  "price": 30571.210292000003,
	  "users": 150,
	  "faixas": [
		{
		  "total": 54,
		  "totalValue": 6709.104800999995
		},
		{
		  "total": 7,
		  "totalValue": 956.6062239999997
		},
		{
		  "total": 13,
		  "totalValue": 2096.3908875
		},
		{
		  "total": 22,
		  "totalValue": 4186.440334999998
		},
		{
		  "total": 18,
		  "totalValue": 3956.4045989999986
		},
		{
		  "total": 19,
		  "totalValue": 5053.1269214999975
		},
		{
		  "total": 5,
		  "totalValue": 1569.1100600000002
		},
		{
		  "total": 7,
		  "totalValue": 2833.751732
		},
		{
		  "total": 2,
		  "totalValue": 987.758548
		},
		{
		  "total": 3,
		  "totalValue": 2222.516184
		}
	  ]
	},
	"c50star": {
	  "price": 5805.400058499999,
	  "users": 20,
	  "faixas": [
		{
		  "total": 8,
		  "totalValue": 1292.226936
		},
		{
		  "total": 0,
		  "value": 0,
		  "totalValue": 0
		},
		{
		  "total": 1,
		  "totalValue": 209.6539515
		},
		{
		  "total": 4,
		  "totalValue": 989.542078
		},
		{
		  "total": 1,
		  "totalValue": 285.74132299999997
		},
		{
		  "total": 2,
		  "totalValue": 691.474581
		},
		{
		  "total": 0,
		  "value": 0,
		  "totalValue": 0
		},
		{
		  "total": 2,
		  "totalValue": 1052.599772
		},
		{
		  "total": 2,
		  "totalValue": 1284.161417
		},
		{
		  "total": 0,
		  "value": 0,
		  "totalValue": 0
		}
	  ]
	},
	"goldnacionalA": {
	  "price": 59099.258158499935,
	  "users": 206,
	  "faixas": [
		{
		  "total": 46,
		  "totalValue": 8269.871904000005
		},
		{
		  "total": 8,
		  "totalValue": 1654.2438920000002
		},
		{
		  "total": 12,
		  "totalValue": 2853.172391999999
		},
		{
		  "total": 43,
		  "totalValue": 11245.572807000002
		},
		{
		  "total": 47,
		  "totalValue": 13516.927987500005
		},
		{
		  "total": 21,
		  "totalValue": 6643.114190999999
		},
		{
		  "total": 15,
		  "totalValue": 5931.574897499999
		},
		{
		  "total": 5,
		  "totalValue": 2570.0667300000005
		},
		{
		  "total": 7,
		  "totalValue": 4677.3966015000005
		},
		{
		  "total": 2,
		  "totalValue": 1737.3167559999997
		}
	  ]
	},
	"goldnacionalB": {
	  "price": 9056.6365295,
	  "users": 25,
	  "faixas": [
		{
		  "total": 4,
		  "totalValue": 895.054622
		},
		{
		  "total": 2,
		  "totalValue": 514.746575
		},
		{
		  "total": 1,
		  "totalValue": 295.947078
		},
		{
		  "total": 4,
		  "totalValue": 1302.0561679999998
		},
		{
		  "total": 11,
		  "totalValue": 3937.6081745
		},
		{
		  "total": 0,
		  "value": 0,
		  "totalValue": 0
		},
		{
		  "total": 0,
		  "value": 0,
		  "totalValue": 0
		},
		{
		  "total": 2,
		  "totalValue": 1279.544056
		},
		{
		  "total": 1,
		  "totalValue": 831.679856
		},
		{
		  "total": 0,
		  "value": 0,
		  "totalValue": 0
		}
	  ]
	},
	"taxes": {
	  "retroativo": "838.29",
	  "subscription": "75",
	  "card": "30",
	  "part": "6383.01"
	},
	"total": "111858.81"
  }

// Reducer
export default function report(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_REPORT':
            return { ...state, ...action.payload };
            break;
        default:
            return state;
    }
}