import React from 'react';
import { connect } from "react-redux";

import { Container, Logo, Title, Span, List, FirstLine, FirstColumns, TitleTable, Column, Line } from './styles';

import logo from '../../assets/logo2.png'

function ViewReport({info}) {
	return (
		<Container>
			{console.log(info)}
			<Logo src={logo}/>
			<Title>Listagem de valores cobrados por Produto <br/> Contrato: 6151 - INSTITUTO DE ASSISTÊNCIA AO SERVIDOR PUBLICO</Title>
			<Span>Período: {info.period}</Span>

			<TitleTable>OURO NACIONAL COLETIVO POR ADESAO QUARTO COLETIVO</TitleTable>
			<List border={1}>
				<FirstLine>
					<FirstColumns>FAIXA ETÁRIA</FirstColumns>
					<FirstColumns>QNT.VIDAS</FirstColumns>
					<FirstColumns>TOTAL POR QNT VIDAS</FirstColumns>
					<FirstColumns>TOTAL</FirstColumns>
				</FirstLine>

				<Line>
					<Column>0 a 18</Column>
					<Column>{info.goldnacionalA.faixas[0].total}</Column>
					<Column>{info.goldnacionalA.faixas[0].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>19 a 23</Column>
					<Column>{info.goldnacionalA.faixas[1].total}</Column>
					<Column>{info.goldnacionalA.faixas[1].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>24 a 28</Column>
					<Column>{info.goldnacionalA.faixas[2].total}</Column>
					<Column>{info.goldnacionalA.faixas[2].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>29 a 33</Column>
					<Column>{info.goldnacionalA.faixas[3].total}</Column>
					<Column>{info.goldnacionalA.faixas[3].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>34 a 38</Column>
					<Column>{info.goldnacionalA.faixas[4].total}</Column>
					<Column>{info.goldnacionalA.faixas[4].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>39 a 43</Column>
					<Column>{info.goldnacionalA.faixas[5].total}</Column>
					<Column>{info.goldnacionalA.faixas[5].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>44 a 88</Column>
					<Column>{info.goldnacionalA.faixas[6].total}</Column>
					<Column>{info.goldnacionalA.faixas[6].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>49 a 53</Column>
					<Column>{info.goldnacionalA.faixas[7].total}</Column>
					<Column>{info.goldnacionalA.faixas[7].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>53 a 58</Column>
					<Column>{info.goldnacionalA.faixas[8].total}</Column>
					<Column>{info.goldnacionalA.faixas[8].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column>59 a 999</Column>
					<Column>{info.goldnacionalA.faixas[9].total}</Column>
					<Column>{info.goldnacionalA.faixas[9].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column></Column>
					<Column><b>{info.goldnacionalA.users}</b></Column>
					<Column></Column>
					<Column><b>{info.goldnacionalA.price.toFixed(4)}</b></Column>
				</Line>
			</List>
		
			<TitleTable>OURO NACIONAL APARTAMENTO POR ADESAO APARTAMENTO</TitleTable>
			<List>
			<FirstLine>
					<FirstColumns>FAIXA ETÁRIA</FirstColumns>
					<FirstColumns>QNT.VIDAS</FirstColumns>
					<FirstColumns>TOTAL POR QNT VIDAS</FirstColumns>
					<FirstColumns>TOTAL</FirstColumns>
				</FirstLine>

				<Line>
					<Column>0 a 18</Column>
					<Column>{info.goldnacionalB.faixas[0].total}</Column>
					<Column>{info.goldnacionalB.faixas[0].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>19 a 23</Column>
					<Column>{info.goldnacionalB.faixas[1].total}</Column>
					<Column>{info.goldnacionalB.faixas[1].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>24 a 28</Column>
					<Column>{info.goldnacionalB.faixas[2].total}</Column>
					<Column>{info.goldnacionalB.faixas[2].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>29 a 33</Column>
					<Column>{info.goldnacionalB.faixas[3].total}</Column>
					<Column>{info.goldnacionalB.faixas[3].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>34 a 38</Column>
					<Column>{info.goldnacionalB.faixas[4].total}</Column>
					<Column>{info.goldnacionalB.faixas[4].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>39 a 43</Column>
					<Column>{info.goldnacionalB.faixas[5].total}</Column>
					<Column>{info.goldnacionalB.faixas[5].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>44 a 88</Column>
					<Column>{info.goldnacionalB.faixas[6].total}</Column>
					<Column>{info.goldnacionalB.faixas[6].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>49 a 53</Column>
					<Column>{info.goldnacionalB.faixas[7].total}</Column>
					<Column>{info.goldnacionalB.faixas[7].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>53 a 58</Column>
					<Column>{info.goldnacionalB.faixas[8].total}</Column>
					<Column>{info.goldnacionalB.faixas[8].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column>59 a 999</Column>
					<Column>{info.goldnacionalB.faixas[9].total}</Column>
					<Column>{info.goldnacionalB.faixas[9].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column></Column>
					<Column><b>{info.goldnacionalB.users}</b></Column>
					<Column></Column>
					<Column><b>{info.goldnacionalB.price.toFixed(4)}</b></Column>
				</Line>
			</List>

			<TitleTable>COMPANY - 50 NACIONAL  ADESÃO COLETIVO</TitleTable>
			<List>
			<FirstLine>
					<FirstColumns>FAIXA ETÁRIA</FirstColumns>
					<FirstColumns>QNT.VIDAS</FirstColumns>
					<FirstColumns>TOTAL POR QNT VIDAS</FirstColumns>
					<FirstColumns>TOTAL</FirstColumns>
				</FirstLine>

				<Line>
					<Column>0 a 18</Column>
					<Column>{info.c50pool.faixas[0].total}</Column>
					<Column>{info.c50pool.faixas[0].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>19 a 23</Column>
					<Column>{info.c50pool.faixas[1].total}</Column>
					<Column>{info.c50pool.faixas[1].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>24 a 28</Column>
					<Column>{info.c50pool.faixas[2].total}</Column>
					<Column>{info.c50pool.faixas[2].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>29 a 33</Column>
					<Column>{info.c50pool.faixas[3].total}</Column>
					<Column>{info.c50pool.faixas[3].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>34 a 38</Column>
					<Column>{info.c50pool.faixas[4].total}</Column>
					<Column>{info.c50pool.faixas[4].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>39 a 43</Column>
					<Column>{info.c50pool.faixas[5].total}</Column>
					<Column>{info.c50pool.faixas[5].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>44 a 88</Column>
					<Column>{info.c50pool.faixas[6].total}</Column>
					<Column>{info.c50pool.faixas[6].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>49 a 53</Column>
					<Column>{info.c50pool.faixas[7].total}</Column>
					<Column>{info.c50pool.faixas[7].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>53 a 58</Column>
					<Column>{info.c50pool.faixas[8].total}</Column>
					<Column>{info.c50pool.faixas[8].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column>59 a 999</Column>
					<Column>{info.c50pool.faixas[9].total}</Column>
					<Column>{info.c50pool.faixas[9].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column></Column>
					<Column><b>{info.c50pool.users}</b></Column>
					<Column></Column>
					<Column><b>{info.c50pool.price.toFixed(4)}</b></Column>
				</Line>
			</List>

			<TitleTable>COMPANY - 50 NACIONAL  ADESÃO STAR</TitleTable>
			<List>
			<FirstLine>
					<FirstColumns>FAIXA ETÁRIA</FirstColumns>
					<FirstColumns>QNT.VIDAS</FirstColumns>
					<FirstColumns>TOTAL POR QNT VIDAS</FirstColumns>
					<FirstColumns>TOTAL</FirstColumns>
				</FirstLine>

				<Line>
					<Column>0 a 18</Column>
					<Column>{info.c50star.faixas[0].total}</Column>
					<Column>{info.c50star.faixas[0].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>19 a 23</Column>
					<Column>{info.c50star.faixas[1].total}</Column>
					<Column>{info.c50star.faixas[1].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>24 a 28</Column>
					<Column>{info.c50star.faixas[2].total}</Column>
					<Column>{info.c50star.faixas[2].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>29 a 33</Column>
					<Column>{info.c50star.faixas[3].total}</Column>
					<Column>{info.c50star.faixas[3].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>34 a 38</Column>
					<Column>{info.c50star.faixas[4].total}</Column>
					<Column>{info.c50star.faixas[4].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>39 a 43</Column>
					<Column>{info.c50star.faixas[5].total}</Column>
					<Column>{info.c50star.faixas[5].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>44 a 88</Column>
					<Column>{info.c50star.faixas[6].total}</Column>
					<Column>{info.c50star.faixas[6].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>49 a 53</Column>
					<Column>{info.c50star.faixas[7].total}</Column>
					<Column>{info.c50star.faixas[7].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>

				<Line>
					<Column>53 a 58</Column>
					<Column>{info.c50star.faixas[8].total}</Column>
					<Column>{info.c50star.faixas[8].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column>59 a 999</Column>
					<Column>{info.c50star.faixas[9].total}</Column>
					<Column>{info.c50star.faixas[9].totalValue.toFixed(4)}</Column>
					<Column></Column>
				</Line>
				<Line>
					<Column></Column>
					<Column><b>{info.c50star.users}</b></Column>
					<Column></Column>
					<Column><b>{info.c50star.price.toFixed(4)}</b></Column>
				</Line>
			</List>

			<TitleTable>PRODUTOS ADICIONAIS</TitleTable>
			<List>
				<FirstLine>
					<FirstColumns>PRODUTO</FirstColumns>
					<FirstColumns>VALOR</FirstColumns>
				</FirstLine>

				<Line>
					<Column>Retroativo {info.taxes.rDescription}</Column>
					<Column>{info.taxes.retroativo}</Column>
				</Line>

				<Line>
					<Column>Taxa de inscrição</Column>
					<Column>{info.taxes.subscription}</Column>
				</Line>

				<Line>
					<Column>Taxa de cartão</Column>
					<Column>{info.taxes.card}</Column>
				</Line>

				<Line>
					<Column>Coparticipação</Column>
					<Column>{info.taxes.part}</Column>
				</Line>

				{info.taxes.discount != '0' && (
					<Line>
						<Column>Débitos</Column>
						<Column>{info.taxes.discount}</Column>
					</Line>
				)}

			</List>

			<TitleTable></TitleTable>
			<List>
				<FirstLine>
					<FirstColumns>VALOR TOTAL</FirstColumns>
				</FirstLine>

				<Line>
					<Column><b>{info.total}</b></Column>
				</Line>

			</List>
		
		</Container>	
	);
}

const mapStateToProps = state => ({
	info: state.report,
})

export default connect(mapStateToProps)(ViewReport)